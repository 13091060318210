import type { ActionButton } from "./ui/ActionButton";
import type { ListControl } from "./ui/ListControl";
import type { TabToolbar } from "./ui/TabToolbar";
import { Icons } from "./ui/Icons";
import { Control } from "./ui/Control";

import * as am5 from "@amcharts/amcharts5";
import * as am5map from "@amcharts/amcharts5/map";
import { MapEditorDefaultTheme } from "./MapEditorDefaultTheme";

import { MapSerializer } from "./MapSerializer";

export interface IMapViewerSettings extends am5.IContainerSettings {

	theme?: "dark" | "light";

	backgroundFill?: am5.Color;
	backgroundFillOpacity?: number;
	backgroundNoise?: boolean;
	//backgroundGradient?: boolean;
	backgroundNoiseColor?: am5.Color;

	// Polygon settings
	polygonFill?: am5.Color;
	polygonFillOpacity?: number;
	polygonStroke?: am5.Color;
	polygonStrokeOpacity?: number;
	polygonStrokeWidth?: number;

	polygonFillHover?: am5.Color;
	polygonFillOpacityHover?: number;
	polygonStrokeHover?: am5.Color;
	polygonStrokeOpacityHover?: number;
	polygonTooltipText?: string;
	polygonInteractive?: boolean;

	polygonAutoZoom?: boolean;

	heatMinFill?: am5.Color;
	heatMaxFill?: am5.Color;
	heatNeutralFill?: am5.Color;
	heatActive?: boolean;
	heatNeutralNoise?: am5.Color;

	// Line settings
	lineStroke?: am5.Color;
	lineStrokeOpacity?: number;
	lineStrokeWidth?: number;
	lineStrokeDashLength?: number;

	lineStrokeHover?: am5.Color;
	lineStrokeOpacityHover?: number;
	lineTooltipText?: string;
	lineInteractive?: boolean;

	// Line point settings
	linePointTypeKey?: string;
	linePointScale?: number;
	linePointStroke?: am5.Color;
	linePointFill?: am5.Color;
	linePointStrokeWidth?: number;
	linePointFillOpacity?: number;
	linePointStrokeOpacity?: number;
	linePointSvgPath?: string;

	linePointFillHover?: am5.Color;
	linePointFillOpacityHover?: number;
	linePointStrokeHover?: am5.Color;
	linePointStrokeOpacityHover?: number;
	linePointTooltipText?: string;
	linePointInteractive?: boolean;

	// Point settings
	pointTypeKey?: string;
	pointScale?: number;
	pointMinScale?: number,
	pointMaxScale?: number
	pointSvgPath?: string;
	pointPinned?: boolean;
	pointFill?: am5.Color;
	pointFillOpacity?: number;
	pointStroke?: am5.Color;
	pointStrokeWidth?: number;
	pointStrokeOpacity?: number;

	pointFillHover?: am5.Color;
	pointFillOpacityHover?: number;
	pointStrokeHover?: am5.Color;
	pointStrokeOpacityHover?: number;
	pointTooltipText?: string;
	pointInteractive?: boolean;

	pointLabelPosition?: "top" | "right" | "bottom" | "left" | "center";
	pointLabelText?: string;

	// Bubble settings
	bubbleMinSize?: number;
	bubbleMaxSize?: number;
	bubbleType?: "Rectangle" | "Hexagon" | "Circle" | "Diamond";

	bubbleFillHover?: am5.Color;
	bubbleFillOpacityHover?: number;
	bubbleStrokeHover?: am5.Color;
	bubbleStrokeOpacityHover?: number;
	bubbleTooltipText?: string;
	bubbleInteractive?: boolean;

	// Pixel settings

	pixelType?: string;
	pixelFillOpacity?: number;
	pixelStroke?: am5.Color;
	pixelStrokeWidth?: number;
	pixelStrokeOpacity?: number;
	pixelSize?: number;
	pixelMinSize?: number;
	pixelMaxSize?: number;
	pixelHorizontalGap?: number;
	pixelVerticalGap?: number;
	pixelSvgPath?: string;

	pixelFillHover?: am5.Color;
	pixelFillOpacityHover?: number;
	pixelStrokeHover?: am5.Color;
	pixelStrokeOpacityHover?: number;
	pixelTooltipText?: string;
	pixelInteractive?: boolean;

	// Label settings
	labelText?: string;
	labelColor?: am5.Color;
	labelOpacity?: number;
	labelFontSizeStep?: number;
	labelMinFontSize?: number;
	labelMaxFontSize?: number;
	labelFontSize?: number;
	labelFontWeight?: "normal" | "bold" | "bolder" | "lighter" | "100" | "200" | "300" | "400" | "500" | "600" | "700" | "800" | "900";
	labelFontStyle?: "normal" | "italic";
	labelTextAlign?: "start" | "end" | "left" | "right" | "center";
	labelPinned?: boolean;
	labelInteractive?: boolean;

	labelColorHover?: am5.Color;
	labelOpacityHover?: am5.Color;
	labelTooltipText?: string;

	clipBackground?: boolean;
	clipGrid?: boolean;

	// Fill color for filler tool
	fillColor?: am5.Color;
}

export interface IMapViewerPrivate extends am5.IContainerPrivate {
	/**
	 * @ignore
	 */
	activeControl?: ActionButton | ListControl;

	listToolbar?: TabToolbar;

	projection?: string;

	geodata?: string;
}

export interface IMapViewerEvents extends am5.IContainerEvents {
	dataitemselected: { target: MapViewer, dataItem: am5.DataItem<am5map.IMapSeriesDataItem>, objectType: "polygon" | "point" | "bubble" | "pixel" | "line" | "label" };
	dataitemunselected: { target: MapViewer, dataItem: am5.DataItem<am5map.IMapSeriesDataItem>, objectType: "polygon" | "point" | "bubble" | "pixel" | "line" | "label" };
}


export class MapViewer extends am5.Container {
	declare public _settings: IMapViewerSettings;
	declare public _privateSettings: IMapViewerPrivate;
	declare public _events: IMapViewerEvents;

	public static className: string = "MapViewer";
	public static classNames: Array<string> = am5.Container.classNames.concat([MapViewer.className]);

	protected _positionOnLineDP?: am5.IDisposer;

	protected _clickedPolygon?: am5map.MapPolygon;
	protected _clickDisposers: Array<am5.IDisposer> = [];
	protected _editor = false;

	public serializer: MapSerializer = MapSerializer.new(this._root, {
		editor: this
	});

	public _neutralPattern = am5.GrainPattern.new(this._root, {
		width: 48,
		height: 48,
		minOpacity: 0.5,
		maxOpacity: 0.5,
		horizontalGap: 2,
		verticalGap: 1
	})

	public map: am5map.MapChart = this.children.push(am5map.MapChart.new(this._root, {
		minZoomLevel: 0.8,
		projection: am5map.geoMercator(),
		panX: "rotateX",
		zoomControl: am5map.ZoomControl.new(this._root, {
			visible: true
		}),
		background: am5.Rectangle.new(this._root, {
			fill: am5.color(0xffffff),
			fillOpacity: 1
		})
	}));

	public linePointSeries: Array<am5map.MapPointSeries> = [];

	public pointTemplate = am5.Template.new<am5.Graphics>({ toggleKey: "active", centerX: am5.p50, centerY: am5.p50 });
	public bubbleTemplate = am5.Template.new<am5.Graphics>({ toggleKey: "active" });
	public pixelTemplate = am5.Template.new<am5.Graphics>({});
	public linePointTemplate = am5.Template.new<am5.Graphics>({ toggleKey: "active", centerX: am5.p50, centerY: am5.p50 });
	public labelTemplate = am5.Template.new<am5.Label>({ toggleKey: "active" });


	public polygonSeries: am5map.MapPolygonSeries = this.map.series.push(am5map.MapPolygonSeries.new(this._root, {
		valueField: "value",
		calculateAggregates: true,
		id: "polygonseries",
		exclude: ["AQ"]
	}));
	public lineSeries: am5map.MapLineSeries = this.map.series.push(am5map.MapLineSeries.new(this._root, {
		layer: 30,
		id: "lineseries"
	}));
	public pointSeries: am5map.MapPointSeries = this.map.series.push(am5map.MapPointSeries.new(this._root, {
		fixedField: "fixed",
		id: "pointseries"
	}));
	public bubbleSeries: am5map.MapPointSeries = this.map.series.push(am5map.MapPointSeries.new(this._root, {
		calculateAggregates: true,
		valueField: "value",
		polygonIdField: "id",
		id: "bubbleseries"
	}));
	public labelSeries: am5map.MapPointSeries = this.map.series.push(am5map.MapPointSeries.new(this._root, {
		fixedField: "fixed",
		id: "labelseries"
	}));


	public gridSeries: am5map.GraticuleSeries = this.map.series.unshift(am5map.GraticuleSeries.new(this._root, { themeTags: ["grid"], affectsBounds: false }));
	public backgroundSeries: am5map.MapPolygonSeries = this.map.series.unshift(am5map.MapPolygonSeries.new(this._root, { visible: false, themeTags: ["polygon", "background"], affectsBounds: false }));

	public drawingLineDataItem: am5.DataItem<am5map.IMapLineSeriesDataItem> | undefined;

	public pixelCountrySeries: { [index: string]: am5map.MapPointSeries } = {};
	protected _cancelAdd: boolean = false;
	protected _shiftDown: boolean = false;

	public _pixelGeoPoint1: am5.IGeoPoint = { latitude: 0, longitude: 0 };
	public _pixelGeoPoint2: am5.IGeoPoint = { latitude: 0.2, longitude: 0 };
	public _bubbleGeoPoint1: am5.IGeoPoint = { latitude: 0, longitude: 0 };
	public _bubbleGeoPoint2: am5.IGeoPoint = { latitude: 0.2, longitude: 0 };

	public _bubbleSize: number = 10;

	public controls: Control[] = [];

	protected _afterNew() {
		this.addTag(this.get("theme", "dark"));

		this._defaultThemes.push(MapEditorDefaultTheme.new(this._root));
		super._afterNew();

		if (!this.get("userData")) {
			this.set("userData", {
				projection: "geoMercator",
				geodata: "worldLow"
			});
		}

		const zoomControl = this.map.get("zoomControl");
		if (zoomControl?.homeButton) {
			zoomControl.homeButton.set("visible", true);
		}

		const tooltip = this._root.container.getTooltip();
		tooltip?.get("background")?.setAll({
			shadowOpacity: 0.2,
			shadowBlur: 3,
			shadowOffsetX: 2,
			shadowOffsetY: 1,
			shadowColor: am5.Color.fromHex(0x000000)
		})

		// this.map.series.events.on("push", (ev) => {
		// 	ev.newValue.set("id", "series_" + ev.newValue.uid);
		// });

		this.events.on("boundschanged", () => {
			// this triggers adapter
			am5.array.each(this.pixelTemplate.entities, (entity) => {
				entity.set("scale", 1 + Math.random());
			})
			am5.array.each(this.bubbleTemplate.entities, (entity) => {
				entity.set("scale", 1 + Math.random());
			})
		})

		// Set up background series
		this.backgroundSeries.data.push({
			id: "bg",
			geometry: am5map.getGeoRectangle(90, 180, -90, -180)
		});
		this.backgroundSeries.mapPolygons.template.set("forceInactive", true);

		this.map.events.on("geoboundschanged", () => {
			this.clipBackground();
		})

		// Set up grid series
		this.gridSeries.dataItems[0].set("id", "grid");
		this.gridSeries.mapLines.template.set("forceInactive", true);

		// polygons
		// polygons are created my map, so we can't pass a template like we do it when creating bullets
		const polygonTemplate = this.polygonSeries.mapPolygons.template;
		polygonTemplate.setAll({
			templateField: "settings"
		});

		polygonTemplate.states.create("hover", {});
		polygonTemplate.events.on("pointerover", (e) => {
			e.target.toFront();
		})
		// end of polygons ////////////////////////////////////////////

		// point series ///////////////////////////////////////////////
		this.pointTemplate.states.create("hover", {});
		this.linePointTemplate.states.create("hover", {});

		this.pointSeries.bullets.push((_root, _target, dataItem: any) => {
			let pointType = dataItem.dataContext.pointType;
			const path = this.get("pointSvgPath");

			if (!pointType) {
				pointType = this.get("pointTypeKey");
				dataItem.dataContext.pointType = pointType;
			}
			if (path) {
				dataItem.dataContext.path = path;
			}
			else {
				delete dataItem.dataContext.path;
			}

			return am5.Bullet.new(this._root, {
				sprite: this._getPointSprite(pointType, this.pointTemplate, path, "point")
			});
		});

		// end of point series

		// bubble series
		this.bubbleTemplate.states.create("hover", {});

		// pixel series
		this.pixelTemplate.states.create("hover", {});

		// line series
		this.lineSeries.mapLines.template.states.create("hover", {});
		this.lineSeries.mapLines.template.set("templateField", "settings");

		// label series ///////////////////////////////////////////////

		this.labelTemplate.states.create("hover", {});
		this.labelSeries.bullets.push((root: am5.Root, target: any, dataItem: any) => {
			return this._getLabelBullet(root, target, dataItem);
		})
	}

	protected _getLabelBullet(_root: am5.Root, _target: any, dataItem: any): am5.Bullet {
		let text = dataItem.dataContext.name;

		if (!text) {
			text = this.get("labelText", "Label");
		}
		const sprite = am5.Label.new(this._root, {
			layer: 30,
			themeTags: ["map", "label"],
			text: text,
			templateField: "settings"
		}, this.labelTemplate as any);

		if (!this.get("labelInteractive")) {
			sprite.events.disableType("pointerover");
			sprite.events.disableType("pointerout");
		}

		return am5.Bullet.new(this._root, {
			sprite: sprite
		});
	}

	protected _addPointEvents(_sprite: am5.Sprite, _type: "point" | "pixel" | "bubble" | "label") {

	}

	public clipBackground() {
		const geoBounds = this.map.geoBounds();
		const dataItem = this.backgroundSeries.dataItems[0];
		if (dataItem) {
			if (this.get("clipBackground")) {
				dataItem.set("geometry", am5map.getGeoRectangle(geoBounds.top, geoBounds.right, geoBounds.bottom, geoBounds.left));
			}
			else {
				dataItem.set("geometry", am5map.getGeoRectangle(90, 180, -90, -180));
			}
		}
	}

	public setPointAnimation(dataItem: any, duration: number, flip: boolean) {
		let userData = dataItem.get("userData");
		if (!userData) {
			userData = {};
			dataItem.set("userData", userData)
		}
		if (userData.animation) {
			userData.animation.stop();
			userData.animation = undefined;

		}
		if (duration) {
			if (!userData) {
				userData = {};
			}

			let easing = am5.ease.linear;
			if (flip) {
				easing = am5.ease.yoyo(am5.ease.linear);
			}

			userData.animation = dataItem.animate({
				key: "positionOnLine",
				from: 0,
				to: 1,
				duration: duration,
				loops: Infinity,
				easing: easing
			});

			userData.flip = flip;

			if (this._positionOnLineDP) {
				this._positionOnLineDP.dispose();
			}

			if (flip) {
				this._positionOnLineDP = dataItem.on("positionOnLine", (value: number) => {
					if (dataItem.dataContext.prevPosition < value) {
						dataItem.set("autoRotateAngle", 0);
					}

					if (dataItem.dataContext.prevPosition > value) {
						dataItem.set("autoRotateAngle", 180);
					}
					dataItem.dataContext.prevPosition = value;
				});
			}
			else {
				dataItem.set("autoRotateAngle", 0);
			}

			dataItem.dataContext.animationDuration = duration;
			dataItem.dataContext.animationFlip = flip;
		}
		else {
			delete dataItem.dataContext.animationDuration;
			delete dataItem.dataContext.animationFlip;
		}
	}


	protected _getPointSprite(pointType: string, template: am5.Template<am5.Graphics>, path?: string, _objectType: "point" = "point"): am5.Graphics {
		let svg = "";
		if (pointType == "Empty") {
			svg = Icons.getPath("Circle");
		}
		else if (pointType == "custom" && path) {
			svg = path;
		}
		else {
			svg = Icons.getPath(pointType);
		}
		const sprite = am5.Graphics.new(this._root, {
			themeTags: ["map", "point", pointType],
			templateField: "settings",
			svgPath: svg,
			layer: 30
		}, template as any);

		sprite.events.on("pointerover", (e) => {
			e.target.toFront();
		})

		return sprite;
	}

	public _createPixelCountrySeries(name: string, fill: am5.Color): am5map.MapPointSeries {
		const map = this.map;
		//const defaultFill = this.pixelTemplate.get("fill", am5.color(0xffffff));

		const pointSeries = map.series.insertIndex(1, am5map.MapPointSeries.new(this._root, {
			longitudeField: "longitude",
			latitudeField: "latitude",
			autoScale: true,
			name: name,
			fill: fill
		}));

		// const dc = dataItem.dataContext as any;
		// if (dc) {
		// 	pointSeries.set("name", dc.name);
		// }

		// if (fill.hex != defaultFill.hex) {
		// 	const dataContext = dataItem.dataContext as any;
		// 	dataContext.settings = am5.Template.new<am5.Graphics>({
		// 		fill: fill
		// 	})
		// }

		pointSeries.bullets.push((root, series, _dataItem) => {
			const type = this.get("pixelType")!;
			const path = this.get("pixelSvgPath");
			const size = this.get("pixelSize", 17)!;

			let sprite: am5.Graphics | undefined;

			if (type == "custom") {
				if (path) {
					sprite = am5.Graphics.new(root, { svgPath: path, layer: 30 });
				}
				else {
					return;
				}
			}
			else {
				switch (type) {
					case "Circle":
						sprite = am5.Circle.new(root, {
							radius: size / 2,
							layer: 30
						} as any, this.pixelTemplate as any);
						break;
					case "Rectangle":
						sprite = am5.Rectangle.new(root, {
							width: size,
							height: size,
							layer: 30
						}, this.pixelTemplate as any);
						break;
					case "Hexagon":
						sprite = am5.Star.new(root, {
							spikes: 3,
							rotation: 0,
							radius: size / 2,
							innerRadius: size / 2,
							layer: 30
						} as any, this.pixelTemplate as any);
						break;
					case "Diamond":
						sprite = am5.Rectangle.new(root, {
							rotation: 45,
							width: size,
							height: size,
							layer: 30
						}, this.pixelTemplate as any);
						break;
				}
			}

			if (sprite) {
				// adapter for scale to handle resize
				sprite.adapters.add("scale", () => {
					return (this.map.convert(this._pixelGeoPoint2).x - this.map.convert(this._pixelGeoPoint1).x) / this.get("pixelSize", 20)
				});
				sprite.set("scale", (this.map.convert(this._pixelGeoPoint2).x - this.map.convert(this._pixelGeoPoint1).x) / this.get("pixelSize", 20))
				sprite.set("centerX", am5.p50);
				sprite.set("centerY", am5.p50);
				sprite.set("fill", series.get("fill"));
				sprite.set("toggleKey", undefined);
				//sprite.set("templateField", "settings");


				sprite.events.on("pointerover", (e) => {
					am5.array.each(series.dataItems, (di) => {
						const bullets = di.bullets;
						if (bullets) {
							let bullet = bullets[0];
							if (bullet) {
								let sprite = bullet.get("sprite");
								if (sprite) {
									sprite.hover();
									sprite.hideTooltip();
								}
							}
						}
					})
					e.target.hover();
				})

				sprite.events.on("pointerout", () => {
					am5.array.each(series.dataItems, (di) => {
						const bullets = di.bullets;
						if (bullets) {
							let bullet = bullets[0];
							if (bullet) {
								let sprite = bullet.get("sprite");
								if (sprite) {
									sprite.unhover();
								}
							}
						}
					})
				})

				if (!this.get("pixelInteractive")) {
					sprite.events.disableType("pointerover");
					sprite.events.disableType("pointerout");
				}

				this._addPointEvents(sprite, "pixel");

				return am5.Bullet.new(root, {
					sprite: sprite
				})
			}
		})
		return pointSeries;
	}


	protected _setPolygonDefaults(key: any, value: any) {
		this.polygonSeries.mapPolygons.each((polygon) => {
			const userData = polygon.get("userData");
			if (!userData || !userData[key]) {
				polygon.resetUserSettings();
				polygon.states.lookup("default")!.set(key, value);

				const dataItem = polygon.dataItem;
				if (dataItem) {
					const dataContext = dataItem.dataContext as any;
					if (dataContext) {
						const settings = dataContext.settings;
						if (settings instanceof am5.Template) {
							settings.set(key, value);
						}
					}
				}
			}
		})

		this.polygonSeries.mapPolygons.template.set(key, value);
	}

	public _updateChildren() {
		super._updateChildren();

		if (this.isDirty("heatNeutralNoise")) {
			this._neutralPattern.set("colors", [this.get("heatNeutralNoise", am5.color(0x000000))]);
		}

		if (!this._editor) {
			if (this.isDirty("polygonAutoZoom")) {

				if (this.get("polygonAutoZoom")) {
					this._clickDisposers = [];
					this.polygonSeries.mapPolygons.each((mapPolygon) => {
						this._clickDisposers.push(
							mapPolygon.events.on("click", () => {
								if (this._clickedPolygon == mapPolygon) {
									this.map.goHome();
									this._clickedPolygon = undefined;
								}
								else {
									this.polygonSeries.zoomToDataItem(mapPolygon.dataItem as any);
									this._clickedPolygon = mapPolygon;
								}
							})
						)
					})
				}
				else {
					am5.array.each(this._clickDisposers, (disposer) => {
						disposer.dispose();
					})
					this._clickDisposers = [];
				}
			}
		}

		if (this.isDirty("clipBackground")) {
			this.clipBackground();
		}
		if (this.isDirty("clipGrid")) {
			this.gridSeries.set("clipExtent", this.get("clipGrid"));
		}


		// polygons ///////////////////////////////////////////////////////////////
		if (this.isDirty("polygonFill")) {
			this._setPolygonDefaults("fill", this.get("polygonFill"))
		}
		if (this.isDirty("polygonFillOpacity")) {
			this._setPolygonDefaults("fillOpacity", this.get("polygonFillOpacity"))
		}
		if (this.isDirty("polygonStroke")) {
			this._setPolygonDefaults("stroke", this.get("polygonStroke"))
		}
		if (this.isDirty("polygonStrokeWidth")) {
			this._setPolygonDefaults("strokeWidth", this.get("polygonStrokeWidth"))
		}
		if (this.isDirty("polygonStrokeOpacity")) {
			this._setPolygonDefaults("strokeOpacity", this.get("polygonStrokeOpacity"))
		}
		if (this.isDirty("polygonTooltipText")) {
			this._setPolygonDefaults("tooltipText", this.get("polygonTooltipText"))
		}

		const polygonTemplate = this.polygonSeries.mapPolygons.template;

		const polygonHoverState = polygonTemplate.states.lookup("hover")!;
		//const polygonActiveState = polygonTemplate.states.lookup("active")!;

		if (this.isDirty("polygonFillHover")) {
			const color = this.get("polygonFillHover");
			if (color) {
				polygonHoverState.set("fill", this.get("polygonFillHover"));
			}
			else {
				polygonHoverState.remove("fill");
			}
		}

		if (this.isDirty("polygonStrokeHover")) {
			const color = this.get("polygonStrokeHover");
			if (color) {
				polygonHoverState.set("stroke", this.get("polygonStrokeHover"));
			}
			else {
				polygonHoverState.remove("stroke");
			}
		}

		if (this.isDirty("polygonFillOpacityHover")) {
			const opacity = this.get("polygonFillOpacityHover");
			if (opacity !== undefined) {
				polygonHoverState.set("fillOpacity", opacity);
			}
			else {
				polygonHoverState.remove("fillOpacity");
			}
		}
		if (this.isDirty("polygonStrokeOpacityHover")) {
			const opacity = this.get("polygonStrokeOpacityHover");
			if (opacity !== undefined) {
				polygonHoverState.set("strokeOpacity", opacity);
			}
			else {
				polygonHoverState.remove("strokeOpacity");
			}
		}

		// end of polygons /////////////////////////////////////////////////////

		if (this.isDirty("lineStroke") || this.isDirty("lineStrokeOpacity") || this.isDirty("lineStrokeWidth") || this.isDirty("lineStrokeDashLength")) {
			const drawingLineDataItem = this.drawingLineDataItem;
			if (drawingLineDataItem) {
				const strokeDashLength = this.get("lineStrokeDashLength", 0);
				const template = am5.Template.new<am5map.MapLine>({
					strokeWidth: this.get("lineStrokeWidth", 1),
					stroke: this.get("lineStroke"),
					strokeOpacity: this.get("lineStrokeOpacity", 1)
				})

				if (strokeDashLength > 0) {
					template.set("strokeDasharray", [strokeDashLength, strokeDashLength]);
				}
				else {
					template.remove("strokeDasharray");
				}

				const dataContext = drawingLineDataItem.dataContext as any;
				if (dataContext) {
					dataContext.settings = template;
				}

				const mapLine = drawingLineDataItem.get("mapLine");
				if (mapLine) {
					mapLine._processTemplateField();
				}
			}
		}

		// toggling interactivity
		if (this.isDirty("polygonInteractive")) {
			if (this.get("polygonInteractive")) {
				this.polygonSeries.mapPolygons.each((mapPolygon) => {
					mapPolygon.events.enableType("pointerover");
					mapPolygon.events.enableType("pointerout");
				})

			}
			else {
				this.polygonSeries.mapPolygons.each((mapPolygon) => {
					mapPolygon.events.disableType("pointerover");
					mapPolygon.events.disableType("pointerout");
				})
			}
		}

		if (this.isDirty("lineInteractive")) {
			if (this.get("lineInteractive")) {
				this.lineSeries.mapLines.each((mapLine) => {
					mapLine.events.enableType("pointerover");
					mapLine.events.enableType("pointerout");
				})
			}
			else {
				this.lineSeries.mapLines.each((mapLine) => {
					mapLine.events.disableType("pointerover");
					mapLine.events.disableType("pointerout");
				})
			}
		}

		if (this.isDirty("linePointInteractive")) {
			am5.array.each(this.linePointSeries, (series) => {
				this._makePointsInteractive(series, this.get("linePointInteractive", true));
			})
		}

		if (this.isDirty("labelInteractive")) {
			this._makePointsInteractive(this.labelSeries, this.get("labelInteractive", true));
		}

		if (this.isDirty("pointInteractive")) {
			this._makePointsInteractive(this.pointSeries, this.get("pointInteractive", true));
		}
		if (this.isDirty("bubbleInteractive")) {
			this._makePointsInteractive(this.bubbleSeries, this.get("bubbleInteractive", true));
		}
		if (this.isDirty("pixelInteractive")) {
			am5.object.each(this.pixelCountrySeries, (_key, series) => {
				this._makePointsInteractive(series, this.get("pixelInteractive", true));
			})
		}

		// end of interactivity toggling

		if (this.isDirty("pointScale")) {

		}

		if (this.isDirty("linePointTypeKey")) {
			//const linePointTypeKey = this.get("linePointTypeKey") as string;
		}

		const pointHoverState = this.pointTemplate.states.lookup("hover") as any;

		if (pointHoverState) {
			if (this.isDirty("pointFillHover")) {
				const color = this.get("pointFillHover");
				if (color) {
					pointHoverState.set("fill", color);
				}
				else {
					pointHoverState.remove("fill");
				}
			}

			if (this.isDirty("pointStrokeHover")) {
				const color = this.get("pointStrokeHover");
				if (color) {
					pointHoverState.set("stroke", color);
				}
				else {
					pointHoverState.remove("stroke");
				}
			}

			if (this.isDirty("pointFillOpacityHover")) {
				const opacity = this.get("pointFillOpacityHover");
				if (opacity !== undefined) {
					pointHoverState.set("fillOpacity", opacity);
				}
				else {
					pointHoverState.remove("fillOpacity");
				}
			}

			if (this.isDirty("pointStrokeOpacityHover")) {
				const opacity = this.get("pointStrokeOpacityHover");
				if (opacity !== undefined) {
					pointHoverState.set("strokeOpacity", opacity);
				}
				else {
					pointHoverState.remove("strokeOpacity");
				}
			}
		}

		if (this.isDirty("pointTooltipText")) {
			this.pointTemplate.set("tooltipText", this.get("pointTooltipText"));
		}

		// line point hover 

		const linePointHoverState = this.linePointTemplate.states.lookup("hover") as any;

		if (linePointHoverState) {
			if (this.isDirty("linePointFillHover")) {
				const color = this.get("linePointFillHover");
				if (color) {
					linePointHoverState.set("fill", color);
				}
				else {
					linePointHoverState.remove("fill");
				}
			}

			if (this.isDirty("linePointStrokeHover")) {
				const color = this.get("linePointStrokeHover");
				if (color) {
					linePointHoverState.set("stroke", color);
				}
				else {
					linePointHoverState.remove("stroke");
				}
			}

			if (this.isDirty("linePointFillOpacityHover")) {
				const opacity = this.get("linePointFillOpacityHover");
				if (opacity !== undefined) {
					linePointHoverState.set("fillOpacity", opacity);
				}
				else {
					linePointHoverState.remove("fillOpacity");
				}
			}

			if (this.isDirty("linePointStrokeOpacityHover")) {
				const opacity = this.get("linePointStrokeOpacityHover");
				if (opacity !== undefined) {
					linePointHoverState.set("strokeOpacity", opacity);
				}
				else {
					linePointHoverState.remove("strokeOpacity");
				}
			}
		}

		if (this.isDirty("linePointTooltipText")) {
			this.linePointTemplate.set("tooltipText", this.get("linePointTooltipText"));
		}

		// bubble hover
		const bubblePointHoverState = this.bubbleTemplate.states.lookup("hover") as any;
		if (bubblePointHoverState) {
			if (this.isDirty("bubbleFillHover")) {
				const color = this.get("bubbleFillHover");
				if (color) {
					bubblePointHoverState.set("fill", color);
				}
				else {
					bubblePointHoverState.remove("fill");
				}
			}
			if (this.isDirty("bubbleStrokeHover")) {
				const color = this.get("bubbleStrokeHover");
				if (color) {
					bubblePointHoverState.set("stroke", color);
				}
				else {
					bubblePointHoverState.remove("stroke");
				}
			}

			if (this.isDirty("bubbleFillOpacityHover")) {
				const opacity = this.get("bubbleFillOpacityHover");
				if (opacity) {
					bubblePointHoverState.set("fillOpacity", opacity);
				}
				else {
					bubblePointHoverState.remove("fillOpacity");
				}
			}
			if (this.isDirty("bubbleStrokeOpacityHover")) {
				const opacity = this.get("bubbleStrokeOpacityHover");
				if (opacity) {
					bubblePointHoverState.set("strokeOpacity", opacity);
				}
				else {
					bubblePointHoverState.remove("strokeOpacity");
				}
			}
		}

		if (this.isDirty("bubbleTooltipText")) {
			this.bubbleTemplate.set("tooltipText", this.get("bubbleTooltipText"));
		}

		const pixelPointHoverState = this.pixelTemplate.states.lookup("hover") as any;
		if (pixelPointHoverState) {
			if (this.isDirty("pixelFillHover")) {
				const color = this.get("pixelFillHover");
				if (color) {
					pixelPointHoverState.set("fill", color);
				}
				else {
					pixelPointHoverState.remove("fill");
				}
			}
			if (this.isDirty("pixelStrokeHover")) {
				const color = this.get("pixelStrokeHover");
				if (color) {
					pixelPointHoverState.set("stroke", color);
				}
				else {
					pixelPointHoverState.remove("stroke");
				}
			}

			if (this.isDirty("pixelFillOpacityHover")) {
				const opacity = this.get("pixelFillOpacityHover");
				if (opacity) {
					pixelPointHoverState.set("fillOpacity", opacity);
				}
				else {
					pixelPointHoverState.remove("fillOpacity");
				}
			}
			if (this.isDirty("pixelStrokeOpacityHover")) {
				const opacity = this.get("pixelStrokeOpacityHover");
				if (opacity) {
					pixelPointHoverState.set("strokeOpacity", opacity);
				}
				else {
					pixelPointHoverState.remove("strokeOpacity");
				}
			}
		}

		if (this.isDirty("pixelTooltipText")) {
			this.pixelTemplate.set("tooltipText", this.get("pixelTooltipText"));
		}

		const labelHoverState = this.labelTemplate.states.lookup("hover") as any;
		if (labelHoverState) {
			if (this.isDirty("labelColorHover")) {
				labelHoverState.set("fill", this.get("labelColorHover"));
			}
		}

		if (this.isDirty("labelTooltipText")) {
			this.labelTemplate.set("tooltipText", this.get("labelTooltipText"));
		}

		const lineHoverState = this.lineSeries.mapLines.template.states.lookup("hover") as any;
		if (lineHoverState) {
			if (this.isDirty("lineStrokeHover")) {
				lineHoverState.set("stroke", this.get("lineStrokeHover"));
			}

			if (this.isDirty("lineStrokeOpacityHover")) {
				lineHoverState.set("strokeOpacity", this.get("lineStrokeOpacityHover"));
			}
		}

		if (this.isDirty("lineTooltipText")) {
			this.lineSeries.mapLines.template.set("tooltipText", this.get("lineTooltipText"));
		}

		// background 
		const background = this.map.get("background")!;
		if (this.isDirty("backgroundFill")) {
			background.set("fill", this.get("backgroundFill"));
		}

		if (this.isDirty("backgroundNoise") || this.isDirty("backgroundNoiseColor")) {
			let pattern;

			if (this.get("backgroundNoise")) {
				pattern = am5.GrainPattern.new(this._root, {
					maxOpacity: 0.08,
					density: 0.2,
					colors: [this.get("backgroundNoiseColor", am5.color(0x000000))]
				})
			}

			background.set("fillPattern", pattern);
		}

		/*
		if (this.isDirty("backgroundGradient") || this.isDirty("backgroundFill")) {
			if (this.get("backgroundGradient")) {
				let fillColor = this.get("backgroundFill");
				if (fillColor) {
					background.set("fillGradient", am5.LinearGradient.new(this._root, {
						stops: [{ color: am5.Color.lighten(fillColor, -0.06) }, { color: fillColor }, { color: am5.Color.lighten(fillColor, -0.06) }]
					}));
				}
			}
			else {
				background.set("fillGradient", undefined);
			}
		}*/

		if (this.isDirty("backgroundFillOpacity")) {
			this.map.get("background")!.set("fillOpacity", this.get("backgroundFillOpacity"));
		}
	}

	public _linkPointLabel(pointDataItem: am5map.IMapPointSeriesDataItem | any, labelDataItem: am5map.IMapPointSeriesDataItem | any) {
		pointDataItem.dataContext.labelId = labelDataItem.dataContext.id;
		labelDataItem.dataContext.pointId = pointDataItem.dataContext.id;

		const point = pointDataItem.bullets[0].get("sprite");
		point._disposers.push(point.on("x", () => this._positionPointLabel(labelDataItem)));
		point._disposers.push(point.on("y", () => this._positionPointLabel(labelDataItem)));
		point._disposers.push(point.on("scale", () => this._positionPointLabel(labelDataItem)));
	}

	public _decorateLinePointSeries(linePointSeries: am5map.MapPointSeries): void {
		linePointSeries.set("id", "linepointseries_" + this.linePointSeries.length);
		this.linePointSeries.push(linePointSeries);

		linePointSeries.bullets.push((_root, _series, dataItem: any) => {
			let pointType = dataItem.dataContext.pointType;

			if (!pointType) {
				pointType = this.get("linePointTypeKey", "");
				dataItem.dataContext.pointType = pointType;
			}
			const path = this.get("linePointSvgPath");

			if (path) {
				dataItem.dataContext.path = path;
			}
			else {
				delete dataItem.dataContext.path;
			}
			const sprite = this._getPointSprite(pointType, this.linePointTemplate, path, "point");
			return am5.Bullet.new(this._root, {
				sprite: sprite
			});
		});
	}


	public _positionPointLabel(labelDataItem: am5map.IMapPointSeriesDataItem | any) {
		const settings = (labelDataItem as any).dataContext;
		let pointDataItem: any = settings.pointSeries.getDataItemById(settings.pointId) as any;
		if (pointDataItem) {
			const label = labelDataItem.bullets[0].get("sprite");
			const point = pointDataItem.bullets[0].get("sprite");
			const paddingH = point.width() / 2 + 5;
			const paddingV = point.height() / 2 + 5;
			switch (settings.labelPosition) {
				case "left":
					label.setAll({
						centerX: am5.p100,
						centerY: am5.p50,
						dx: -paddingH,
						dy: 0
					});
					break;
				case "right":
					label.setAll({
						centerX: am5.p0,
						centerY: am5.p50,
						dx: paddingH,
						dy: 0
					});
					break;
				case "top":
					label.setAll({
						centerX: am5.p50,
						centerY: am5.p100,
						dx: 0,
						dy: -paddingV
					});
					break;
				case "bottom":
					label.setAll({
						centerX: am5.p50,
						centerY: am5.p0,
						dx: 0,
						dy: paddingV
					});
					break;
				default:
					label.setAll({
						centerX: am5.p50,
						centerY: am5.p50,
						dx: 0,
						dy: 0
					});
					break;
			}

			if (labelDataItem.dataContext.fixed) {
				label.setAll({
					x: point.get("x"),
					y: point.get("y")
				});
			}

			labelDataItem.setAll({
				longitude: pointDataItem.get("longitude"),
				latitude: pointDataItem.get("latitude")
			});

		}
	}

	protected _createLabel(geoPoint: am5.IGeoPoint, pinned: boolean, point?: am5.IPoint, text?: string): any {
		const id = this.labelSeries.data.length + 1 + "";

		const templateSettings: any = {
			text: "{name}",
			fill: this.get("labelColor"),
			fillOpacity: this.get("labelOpacity"),
			fontSize: this.get("labelFontSize") + "em",
			fontWeight: this.get("labelFontWeight"),
			textAlign: this.get("labelTextAlign")
		};

		if (point) {
			templateSettings.x = am5.percent(point.x / this.map.width() * 100);
			templateSettings.y = am5.percent(point.y / this.map.height() * 100);
		}

		return this.labelSeries.data.push({
			fixed: !pinned,
			geometry: {
				type: "Point", coordinates: [geoPoint.longitude, geoPoint.latitude]
			},
			id: "label_" + id,
			name: text || this.get("labelText"),
			populateText: true,
			settings: am5.Template.new<am5.Label>(templateSettings)
		});
	}

	protected _makePointsInteractive(series: am5map.MapPointSeries, interactive: boolean) {
		am5.array.each(series.dataItems, (dataItem) => {
			const bullets = dataItem.bullets;
			if (bullets) {
				am5.array.each(bullets, (bullet) => {
					const sprite = bullet.get("sprite");
					if (sprite) {
						if (interactive) {
							sprite.events.enableType("pointerover");
							sprite.events.enableType("pointerout");
						}
						else {
							sprite.events.disableType("pointerover");
							sprite.events.disableType("pointerout");
						}
					}
				})
			}
		});
	}

	public _decorateBubbleSeries(useTemplatePixels: boolean = false) {
		let w = this.map.seriesContainer.width();
		let h = this.map.seriesContainer.height();

		const bubbleSeries = this.bubbleSeries;
		const template = this.bubbleTemplate;

		const userData = template.get("userData", {});
		const type = userData.pointType;
		const bubbleMinSize = userData.bubbleMinSize;
		const bubbleMaxSize = userData.bubbleMaxSize;
		const step = userData.bubbleMinSize;

		if (useTemplatePixels && userData.point1 && userData.point2) {
			this._bubbleGeoPoint1 = userData.point1;
			this._bubbleGeoPoint2 = userData.point2;
		}
		else {
			this._bubbleGeoPoint1 = this.map.invert({ x: w / 2, y: h / 2 });
			this._bubbleGeoPoint2 = this.map.invert({ x: w / 2 + bubbleMaxSize, y: h / 2 });
		}
		this._bubbleSize = bubbleMaxSize;

		bubbleSeries.data.clear();
		bubbleSeries.bullets.clear();

		bubbleSeries.bullets.push((root, _series, _dataItem) => {
			let sprite: am5.Graphics | undefined;
			switch (type) {
				case "Circle":
					sprite = am5.Circle.new(root, {
						radius: step / 2
					} as any, template as any);
					break;
				case "Rectangle":
					sprite = am5.Rectangle.new(root, {
						width: step,
						height: step
					} as any, template as any);
					break;
				case "Hexagon":
					sprite = am5.Star.new(root, {
						spikes: 3,
						rotation: 0,
						radius: step / 2,
						innerRadius: step / 2
					} as any, template as any);
					break;
				case "Diamond":
					sprite = am5.Rectangle.new(root, {
						rotation: 45,
						width: step,
						height: step
					} as any, template as any);
					break;
			}

			if (sprite) {
				sprite.setAll({
					layer: 30,
					templateField: "settings",
					themeTags: ["map", "point"]
				});

				// adapter for scale to handle resize
				sprite.adapters.add("scale", () => {
					return (this.map.convert(this._bubbleGeoPoint2).x - this.map.convert(this._bubbleGeoPoint1).x) / this._bubbleSize;
				})

				this._addPointEvents(sprite, "bubble");
				this._addBubbleInteractivity(sprite);


				if (!this.get("bubbleInteractive")) {
					sprite.events.disableType("pointerover");
					sprite.events.disableType("pointerout");
				}
			}

			return am5.Bullet.new(this._root, {
				sprite: sprite!
			});
		});

		if (type == "Circle") {
			bubbleSeries.set("heatRules", [{
				target: template,
				min: bubbleMinSize / 2,
				max: bubbleMaxSize / 2,
				key: "radius",
				dataField: "value"
			}]);
		}
		else if (type == "Hexagon") {
			bubbleSeries.set("heatRules", [{
				target: template,
				min: bubbleMinSize / 2,
				max: bubbleMaxSize / 2,
				key: "radius",
				dataField: "value"
			}, {
				target: template,
				min: bubbleMinSize / 2,
				max: bubbleMaxSize / 2,
				key: "innerRadius",
				dataField: "value"
			}]);
		}
		else {
			bubbleSeries.set("heatRules", [{
				target: template,
				min: bubbleMinSize,
				max: bubbleMaxSize,
				key: "width",
				dataField: "value"
			}, {
				target: template,
				min: bubbleMinSize,
				max: bubbleMaxSize,
				key: "height",
				dataField: "value"
			}]);
		}
	}

	public _initPixelData(useTemplatePixels: boolean = false) {
		const map = this.map;
		const w = map.seriesContainer.width();
		const h = map.seriesContainer.height();
		const size = this.get("pixelSize", 17)!;
		const templatePixels = this.pixelTemplate.get("userData");
		if (useTemplatePixels && templatePixels) {
			this._pixelGeoPoint1 = templatePixels.point1;
			this._pixelGeoPoint2 = templatePixels.point2;
		}
		else {
			this._pixelGeoPoint1 = this.map.invert({ x: w / 2, y: h / 2 });
			this._pixelGeoPoint2 = this.map.invert({ x: w / 2 + size, y: h / 2 });
		}

		this.pixelTemplate.set("userData", {
			point1: this._pixelGeoPoint1,
			point2: this._pixelGeoPoint2
		});
	}

	protected _addBubbleInteractivity(_sprite: am5.Sprite) {
		// void
	}

	public _setPatterns(series: am5map.MapPolygonSeries) {
		am5.array.each(series.dataItems, (dataItem) => {
			const polygon = dataItem.get("mapPolygon");
			if (polygon) {				
				if (dataItem.get("value") == undefined) {
					polygon.set("fillPattern", this._neutralPattern);
				}
				else {
					polygon.set("fillPattern", undefined);
				}
			}
		})
	}

	public _resetHeatValues(series: am5map.MapSeries) {
		am5.array.each(series.dataItems, (dataItem) => {			
			dataItem.remove("value");
		})
	}

	public setPolygonHeat(active: boolean, series: am5map.MapPolygonSeries) {
		if (active) {
			this.setAll({
				heatMinFill: this.get("heatMinFill"),
				heatMaxFill: this.get("heatMaxFill"),
				heatNeutralFill: this.get("heatNeutralFill"),
				heatActive: true
			})

			series.set("heatRules", [{
				target: series.mapPolygons.template,
				dataField: "value",
				min: this.get("heatMinFill"),
				max: this.get("heatMaxFill"),
				neutral: this.get("heatNeutralFill"),
				key: "fill"
			}]);

			this._setPatterns(series);
		}
		else {
			// this.remove("heatActive");
			// this.remove("heatMinFill");
			// this.remove("heatMaxFill");
			// this.remove("heatNeutralFill");
			series.set("heatRules", []);

			series.mapPolygons.each((mapPolygon) => {
				mapPolygon.resetUserSettings();
				mapPolygon.states.lookup("default")!.setAll({
					fill: this.get("polygonFill"),
					fillPattern: undefined
				});
				mapPolygon._applyTemplates(false);
			})
		}
	}

	public loadMap(geodataName: string): Promise<any> {
		this.get("userData")["geodata"] = geodataName;
		return am5.net.load("https://cdn.amcharts.com/lib/5/geodata/json/" + geodataName + ".json", this).then((res: any) => {
			const geodata = am5.JSONParser.parse(res.response);
			this.polygonSeries.set("geoJSON", geodata);
		});
	}

	public getProjection(projectionName: string | undefined): any {
		let projection = am5map.geoMercator();
		switch (projectionName) {
			case "geoOrthographic":
				projection = am5map.geoOrthographic();
				break;
			case "geoEquirectangular":
				projection = am5map.geoEquirectangular();
				break;
			case "geoEqualEarth":
				projection = am5map.geoEqualEarth();
				break;
			case "geoNaturalEarth1":
				projection = am5map.geoNaturalEarth1();
				break;
			case "geoAlbersUsa":
				projection = am5map.geoAlbersUsa();
				break;
		}
		return projection;
	}

	public getControlByName(name: string): Control | undefined {
		const controls = this.controls;
		for (let i = 0; i < controls.length; i++) {
			if (controls[i].className == name) {
				return controls[i];
			}
		}
	}

}
