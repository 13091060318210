import * as am5 from "@amcharts/amcharts5";
import am5geodata_worldLow from "@amcharts/amcharts5-geodata/worldLow";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
import { MapViewer } from "./.internal/MapViewer";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

export { MapViewer } from "./.internal/MapViewer";

export function create(target: string | HTMLElement, config: any) {

	// Root
	const editorURL = "https://amcharts.com/editor/map/";
	const root = am5.Root.new(target);
	root.setThemes([am5themes_Animated.new(root)]);

	// Grab settings that need to be there for MapViewer constructor
	let settings: any = {};
	if (config && config.settings && config.settings.editor) {
		settings = {
			theme: config.settings.editor.theme,
			userData: config.settings.editor.userData
		};
	};


	// Set up map viewer
	const editor = root.container.children.push(MapViewer.new(root, settings));
	editor.polygonSeries.set("geoJSON", am5geodata_worldLow);


	// Not needed anymore
	//editor.polygonSeries.set("geoJSON", { type: "FeatureCollection", features: [] });

	// Exporting menu
	const exporting = am5plugins_exporting.Exporting.new(root, {
		menu: am5plugins_exporting.ExportingMenu.new(root, {})
	});
	const menuitems = exporting.get("menu")!.get("items")!;

	menuitems.push({
		type: "separator"
	});

	menuitems.push({
		type: "custom",
		label: "About this map",
		callback: function() {
			let modal!: am5.Modal;
			if (!modal) {
				modal = am5.Modal.new(root, {
					content: `<p><strong>This was created using the free amCharts Map Editor tool</strong></p>
<p>To create your own, visit:<br><a href="${editorURL}" target="_blank">${editorURL}</a></p>
<p>To find out more about amCharts, visit:<br><a href="https://amcharts.com/" target="_blank">https://amcharts.com/</a></p>`
				});

				setTimeout(() => {
					let okButton = document.createElement("input");
					okButton.type = "button";
					okButton.value = "OK";
					okButton.addEventListener("click", () => {
						modal.close();
					});

					modal.getPrivate("content").appendChild(okButton);
				}, 100);

			}

			modal.open();
		}
	});

	// Set up export menu colors based on theme
	if (settings.theme != "light") {
		new am5.utils.StyleRule(am5.utils.getShadowRoot(root.dom), ".am5exporting-menu path", {
			"fill": "#aaa"
		});

		new am5.utils.StyleRule(am5.utils.getShadowRoot(root.dom), ".am5exporting-menu:hover path, .am5exporting-menu-open path", {
			"fill": "#555"
		});
		 
	}

	// Import data
	root.events.once("frameended", () => {
		//setTimeout(() => {
			editor.serializer.importData(JSON.stringify(config));
		//}, 3000)
	});
}
