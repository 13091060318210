/**
 * This file is automatically generated by `yarn generate-classes`.
 * DO NOT MANUALLY EDIT IT OR YOUR CHANGES WILL BE LOST!
 */

import type { AccelerationBands } from "./../../../stock";
import type { AccumulationDistribution } from "./../../../stock";
import type { AccumulativeSwingIndex } from "./../../../stock";
import type { Annotator } from "./../../../plugins/exporting";
import type { ArcDiagram } from "./../../../flow";
import type { ArcDiagramLink } from "./../../../flow";
import type { ArcDiagramNodes } from "./../../../flow";
import type { Aroon } from "./../../../stock";
import type { AverageSeries } from "./../../../stock";
import type { AverageTrueRange } from "./../../../stock";
import type { AwesomeOscillator } from "./../../../stock";
import type { Axis } from "./../../../xy";
import type { AxisBullet } from "./../../../xy";
import type { AxisLabel } from "./../../../xy";
import type { AxisLabelRadial } from "./../../../xy";
import type { AxisRenderer } from "./../../../xy";
import type { AxisRendererCircular } from "./../../../radar";
import type { AxisRendererRadial } from "./../../../radar";
import type { AxisRendererX } from "./../../../xy";
import type { AxisRendererY } from "./../../../xy";
import type { AxisTick } from "./../../../xy";
import type { BaseColumnSeries } from "./../../../xy";
import type { BollingerBands } from "./../../../stock";
import type { BreadcrumbBar } from "./../../../hierarchy";
import type { BullBearPower } from "./../../../stock";
import type { Bullet } from "./../../../index";
import type { Button } from "./../../../index";
import type { CalloutSeries } from "./../../../stock";
import type { Candlestick } from "./../../../xy";
import type { CandlestickSeries } from "./../../../xy";
import type { CategoryAxis } from "./../../../xy";
import type { CategoryDateAxis } from "./../../../xy";
import type { ChaikinMoneyFlow } from "./../../../stock";
import type { ChaikinOscillator } from "./../../../stock";
import type { Chart } from "./../../../index";
import type { ChartIndicator } from "./../../../stock";
import type { Chord } from "./../../../flow";
import type { ChordDirected } from "./../../../flow";
import type { ChordLink } from "./../../../flow";
import type { ChordLinkDirected } from "./../../../flow";
import type { ChordNodes } from "./../../../flow";
import type { ChordNonRibbon } from "./../../../flow";
import type { Circle } from "./../../../index";
import type { CirclePattern } from "./../../../index";
import type { ClockHand } from "./../../../radar";
import type { ClusteredPointSeries } from "./../../../map";
import type { ColorControl } from "./../../../stock";
import type { ColorSet } from "./../../../index";
import type { ColumnSeries } from "./../../../xy";
import type { CommodityChannelIndex } from "./../../../stock";
import type { ComparisonControl } from "./../../../stock";
import type { Component } from "./../../../index";
import type { Container } from "./../../../index";
import type { DataSaveControl } from "./../../../stock";
import type { DateAxis } from "./../../../xy";
import type { DateRangeSelector } from "./../../../stock";
import type { DisparityIndex } from "./../../../stock";
import type { DoodleSeries } from "./../../../stock";
import type { DrawingControl } from "./../../../stock";
import type { DrawingSeries } from "./../../../stock";
import type { DrawingToolControl } from "./../../../stock";
import type { Dropdown } from "./../../../stock";
import type { DropdownColors } from "./../../../stock";
import type { DropdownControl } from "./../../../stock";
import type { DropdownList } from "./../../../stock";
import type { DropdownListControl } from "./../../../stock";
import type { DurationAxis } from "./../../../xy";
import type { EditableLabel } from "./../../../index";
import type { Ellipse } from "./../../../index";
import type { EllipseSeries } from "./../../../stock";
import type { Entity } from "./../../../index";
import type { Exporting } from "./../../../plugins/exporting";
import type { ExportingMenu } from "./../../../plugins/exporting";
import type { FibonacciSeries } from "./../../../stock";
import type { FibonacciTimezoneSeries } from "./../../../stock";
import type { Flow } from "./../../../flow";
import type { FlowLink } from "./../../../flow";
import type { FlowNode } from "./../../../flow";
import type { FlowNodes } from "./../../../flow";
import type { ForceDirected } from "./../../../hierarchy";
import type { FunnelSeries } from "./../../../percent";
import type { FunnelSlice } from "./../../../percent";
import type { GaplessDateAxis } from "./../../../xy";
import type { Gradient } from "./../../../index";
import type { GrainPattern } from "./../../../index";
import type { Graphics } from "./../../../index";
import type { GraticuleSeries } from "./../../../map";
import type { Grid } from "./../../../xy";
import type { GridLayout } from "./../../../index";
import type { HeatLegend } from "./../../../index";
import type { Hierarchy } from "./../../../hierarchy";
import type { HierarchyLink } from "./../../../hierarchy";
import type { HierarchyNode } from "./../../../hierarchy";
import type { HorizontalLayout } from "./../../../index";
import type { HorizontalLineSeries } from "./../../../stock";
import type { HorizontalRaySeries } from "./../../../stock";
import type { IconControl } from "./../../../stock";
import type { IconSeries } from "./../../../stock";
import type { Indicator } from "./../../../stock";
import type { IndicatorControl } from "./../../../stock";
import type { InterfaceColors } from "./../../../index";
import type { IntervalControl } from "./../../../stock";
import type { Label } from "./../../../index";
import type { LabelSeries } from "./../../../stock";
import type { Layout } from "./../../../index";
import type { Legend } from "./../../../index";
import type { Line } from "./../../../index";
import type { LinePattern } from "./../../../index";
import type { LineSeries } from "./../../../xy";
import type { LinearGradient } from "./../../../index";
import type { LinkedHierarchy } from "./../../../hierarchy";
import type { LinkedHierarchyNode } from "./../../../hierarchy";
import type { MACD } from "./../../../stock";
import type { MACross } from "./../../../stock";
import type { MapChart } from "./../../../map";
import type { MapLine } from "./../../../map";
import type { MapLineSeries } from "./../../../map";
import type { MapPointSeries } from "./../../../map";
import type { MapPolygon } from "./../../../map";
import type { MapPolygonSeries } from "./../../../map";
import type { MapSeries } from "./../../../map";
import type { Measure } from "./../../../stock";
import type { MedianPrice } from "./../../../stock";
import type { Modal } from "./../../../index";
import type { Momentum } from "./../../../stock";
import type { MovingAverage } from "./../../../stock";
import type { MovingAverageDeviation } from "./../../../stock";
import type { MovingAverageEnvelope } from "./../../../stock";
import type { OHLC } from "./../../../xy";
import type { OHLCSeries } from "./../../../xy";
import type { OnBalanceVolume } from "./../../../stock";
import type { OverboughtOversold } from "./../../../stock";
import type { PVT } from "./../../../stock";
import type { Pack } from "./../../../hierarchy";
import type { PanelControls } from "./../../../stock";
import type { ParallelChannelSeries } from "./../../../stock";
import type { Partition } from "./../../../hierarchy";
import type { PathPattern } from "./../../../index";
import type { Pattern } from "./../../../index";
import type { PatternSet } from "./../../../index";
import type { PercentChart } from "./../../../percent";
import type { PercentSeries } from "./../../../percent";
import type { PeriodSelector } from "./../../../stock";
import type { PictorialStackedSeries } from "./../../../percent";
import type { Picture } from "./../../../index";
import type { PicturePattern } from "./../../../index";
import type { PieChart } from "./../../../percent";
import type { PieSeries } from "./../../../percent";
import type { PointedRectangle } from "./../../../index";
import type { Polygon } from "./../../../index";
import type { PolylineSeries } from "./../../../stock";
import type { PyramidSeries } from "./../../../percent";
import type { QuadrantLineSeries } from "./../../../stock";
import type { RadarChart } from "./../../../radar";
import type { RadarColumnSeries } from "./../../../radar";
import type { RadarCursor } from "./../../../radar";
import type { RadarLineSeries } from "./../../../radar";
import type { RadialGradient } from "./../../../index";
import type { RadialLabel } from "./../../../index";
import type { RadialText } from "./../../../index";
import type { Rectangle } from "./../../../index";
import type { RectanglePattern } from "./../../../index";
import type { RectangleSeries } from "./../../../stock";
import type { RegressionSeries } from "./../../../stock";
import type { RelativeStrengthIndex } from "./../../../stock";
import type { ResetControl } from "./../../../stock";
import type { RoundedRectangle } from "./../../../index";
import type { Sankey } from "./../../../flow";
import type { SankeyLink } from "./../../../flow";
import type { SankeyNodes } from "./../../../flow";
import type { Scrollbar } from "./../../../index";
import type { SerialChart } from "./../../../index";
import type { Serializer } from "./../../../plugins/json";
import type { Series } from "./../../../index";
import type { SeriesTypeControl } from "./../../../stock";
import type { SettingsControl } from "./../../../stock";
import type { SettingsModal } from "./../../../stock";
import type { SimpleLineSeries } from "./../../../stock";
import type { Slice } from "./../../../index";
import type { SliceGrouper } from "./../../../plugins/sliceGrouper";
import type { SlicedChart } from "./../../../percent";
import type { Slider } from "./../../../index";
import type { SmoothedRadarLineSeries } from "./../../../radar";
import type { SmoothedXLineSeries } from "./../../../xy";
import type { SmoothedXYLineSeries } from "./../../../xy";
import type { SmoothedYLineSeries } from "./../../../xy";
import type { Sprite } from "./../../../index";
import type { SpriteResizer } from "./../../../index";
import type { StandardDeviation } from "./../../../stock";
import type { Star } from "./../../../index";
import type { StepLineSeries } from "./../../../xy";
import type { StochasticMomentumIndex } from "./../../../stock";
import type { StochasticOscillator } from "./../../../stock";
import type { StockChart } from "./../../../stock";
import type { StockControl } from "./../../../stock";
import type { StockLegend } from "./../../../stock";
import type { StockPanel } from "./../../../stock";
import type { StockToolbar } from "./../../../stock";
import type { Sunburst } from "./../../../hierarchy";
import type { Text } from "./../../../index";
import type { Tick } from "./../../../index";
import type { Tooltip } from "./../../../index";
import type { Tree } from "./../../../hierarchy";
import type { Treemap } from "./../../../hierarchy";
import type { TrendLineSeries } from "./../../../stock";
import type { Triangle } from "./../../../index";
import type { Trix } from "./../../../stock";
import type { TypicalPrice } from "./../../../stock";
import type { VWAP } from "./../../../stock";
import type { ValueAxis } from "./../../../xy";
import type { Venn } from "./../../../venn";
import type { VerticalLayout } from "./../../../index";
import type { VerticalLineSeries } from "./../../../stock";
import type { Volume } from "./../../../stock";
import type { VolumeProfile } from "./../../../stock";
import type { VoronoiTreemap } from "./../../../hierarchy";
import type { WilliamsR } from "./../../../stock";
import type { WordCloud } from "./../../../wc";
import type { XYChart } from "./../../../xy";
import type { XYChartScrollbar } from "./../../../xy";
import type { XYCursor } from "./../../../xy";
import type { XYSeries } from "./../../../xy";
import type { ZigZag } from "./../../../stock";
import type { ZoomControl } from "./../../../map";
import type { ZoomTools } from "./../../../index";
import type { ZoomableContainer } from "./../../../index";

export interface IClasses {
	"AccelerationBands": () => Promise<typeof AccelerationBands>;
	"AccumulationDistribution": () => Promise<typeof AccumulationDistribution>;
	"AccumulativeSwingIndex": () => Promise<typeof AccumulativeSwingIndex>;
	"Annotator": () => Promise<typeof Annotator>;
	"ArcDiagram": () => Promise<typeof ArcDiagram>;
	"ArcDiagramLink": () => Promise<typeof ArcDiagramLink>;
	"ArcDiagramNodes": () => Promise<typeof ArcDiagramNodes>;
	"Aroon": () => Promise<typeof Aroon>;
	"AverageSeries": () => Promise<typeof AverageSeries>;
	"AverageTrueRange": () => Promise<typeof AverageTrueRange>;
	"AwesomeOscillator": () => Promise<typeof AwesomeOscillator>;
	"Axis": () => Promise<typeof Axis>;
	"AxisBullet": () => Promise<typeof AxisBullet>;
	"AxisLabel": () => Promise<typeof AxisLabel>;
	"AxisLabelRadial": () => Promise<typeof AxisLabelRadial>;
	"AxisRenderer": () => Promise<typeof AxisRenderer>;
	"AxisRendererCircular": () => Promise<typeof AxisRendererCircular>;
	"AxisRendererRadial": () => Promise<typeof AxisRendererRadial>;
	"AxisRendererX": () => Promise<typeof AxisRendererX>;
	"AxisRendererY": () => Promise<typeof AxisRendererY>;
	"AxisTick": () => Promise<typeof AxisTick>;
	"BaseColumnSeries": () => Promise<typeof BaseColumnSeries>;
	"BollingerBands": () => Promise<typeof BollingerBands>;
	"BreadcrumbBar": () => Promise<typeof BreadcrumbBar>;
	"BullBearPower": () => Promise<typeof BullBearPower>;
	"Bullet": () => Promise<typeof Bullet>;
	"Button": () => Promise<typeof Button>;
	"CalloutSeries": () => Promise<typeof CalloutSeries>;
	"Candlestick": () => Promise<typeof Candlestick>;
	"CandlestickSeries": () => Promise<typeof CandlestickSeries>;
	"CategoryAxis": () => Promise<typeof CategoryAxis>;
	"CategoryDateAxis": () => Promise<typeof CategoryDateAxis>;
	"ChaikinMoneyFlow": () => Promise<typeof ChaikinMoneyFlow>;
	"ChaikinOscillator": () => Promise<typeof ChaikinOscillator>;
	"Chart": () => Promise<typeof Chart>;
	"ChartIndicator": () => Promise<typeof ChartIndicator>;
	"Chord": () => Promise<typeof Chord>;
	"ChordDirected": () => Promise<typeof ChordDirected>;
	"ChordLink": () => Promise<typeof ChordLink>;
	"ChordLinkDirected": () => Promise<typeof ChordLinkDirected>;
	"ChordNodes": () => Promise<typeof ChordNodes>;
	"ChordNonRibbon": () => Promise<typeof ChordNonRibbon>;
	"Circle": () => Promise<typeof Circle>;
	"CirclePattern": () => Promise<typeof CirclePattern>;
	"ClockHand": () => Promise<typeof ClockHand>;
	"ClusteredPointSeries": () => Promise<typeof ClusteredPointSeries>;
	"ColorControl": () => Promise<typeof ColorControl>;
	"ColorSet": () => Promise<typeof ColorSet>;
	"ColumnSeries": () => Promise<typeof ColumnSeries>;
	"CommodityChannelIndex": () => Promise<typeof CommodityChannelIndex>;
	"ComparisonControl": () => Promise<typeof ComparisonControl>;
	"Component": () => Promise<typeof Component>;
	"Container": () => Promise<typeof Container>;
	"DataSaveControl": () => Promise<typeof DataSaveControl>;
	"DateAxis": () => Promise<typeof DateAxis>;
	"DateRangeSelector": () => Promise<typeof DateRangeSelector>;
	"DisparityIndex": () => Promise<typeof DisparityIndex>;
	"DoodleSeries": () => Promise<typeof DoodleSeries>;
	"DrawingControl": () => Promise<typeof DrawingControl>;
	"DrawingSeries": () => Promise<typeof DrawingSeries>;
	"DrawingToolControl": () => Promise<typeof DrawingToolControl>;
	"Dropdown": () => Promise<typeof Dropdown>;
	"DropdownColors": () => Promise<typeof DropdownColors>;
	"DropdownControl": () => Promise<typeof DropdownControl>;
	"DropdownList": () => Promise<typeof DropdownList>;
	"DropdownListControl": () => Promise<typeof DropdownListControl>;
	"DurationAxis": () => Promise<typeof DurationAxis>;
	"EditableLabel": () => Promise<typeof EditableLabel>;
	"Ellipse": () => Promise<typeof Ellipse>;
	"EllipseSeries": () => Promise<typeof EllipseSeries>;
	"Entity": () => Promise<typeof Entity>;
	"Exporting": () => Promise<typeof Exporting>;
	"ExportingMenu": () => Promise<typeof ExportingMenu>;
	"FibonacciSeries": () => Promise<typeof FibonacciSeries>;
	"FibonacciTimezoneSeries": () => Promise<typeof FibonacciTimezoneSeries>;
	"Flow": () => Promise<typeof Flow>;
	"FlowLink": () => Promise<typeof FlowLink>;
	"FlowNode": () => Promise<typeof FlowNode>;
	"FlowNodes": () => Promise<typeof FlowNodes>;
	"ForceDirected": () => Promise<typeof ForceDirected>;
	"FunnelSeries": () => Promise<typeof FunnelSeries>;
	"FunnelSlice": () => Promise<typeof FunnelSlice>;
	"GaplessDateAxis": () => Promise<typeof GaplessDateAxis>;
	"Gradient": () => Promise<typeof Gradient>;
	"GrainPattern": () => Promise<typeof GrainPattern>;
	"Graphics": () => Promise<typeof Graphics>;
	"GraticuleSeries": () => Promise<typeof GraticuleSeries>;
	"Grid": () => Promise<typeof Grid>;
	"GridLayout": () => Promise<typeof GridLayout>;
	"HeatLegend": () => Promise<typeof HeatLegend>;
	"Hierarchy": () => Promise<typeof Hierarchy>;
	"HierarchyLink": () => Promise<typeof HierarchyLink>;
	"HierarchyNode": () => Promise<typeof HierarchyNode>;
	"HorizontalLayout": () => Promise<typeof HorizontalLayout>;
	"HorizontalLineSeries": () => Promise<typeof HorizontalLineSeries>;
	"HorizontalRaySeries": () => Promise<typeof HorizontalRaySeries>;
	"IconControl": () => Promise<typeof IconControl>;
	"IconSeries": () => Promise<typeof IconSeries>;
	"Indicator": () => Promise<typeof Indicator>;
	"IndicatorControl": () => Promise<typeof IndicatorControl>;
	"InterfaceColors": () => Promise<typeof InterfaceColors>;
	"IntervalControl": () => Promise<typeof IntervalControl>;
	"Label": () => Promise<typeof Label>;
	"LabelSeries": () => Promise<typeof LabelSeries>;
	"Layout": () => Promise<typeof Layout>;
	"Legend": () => Promise<typeof Legend>;
	"Line": () => Promise<typeof Line>;
	"LinePattern": () => Promise<typeof LinePattern>;
	"LineSeries": () => Promise<typeof LineSeries>;
	"LinearGradient": () => Promise<typeof LinearGradient>;
	"LinkedHierarchy": () => Promise<typeof LinkedHierarchy>;
	"LinkedHierarchyNode": () => Promise<typeof LinkedHierarchyNode>;
	"MACD": () => Promise<typeof MACD>;
	"MACross": () => Promise<typeof MACross>;
	"MapChart": () => Promise<typeof MapChart>;
	"MapLine": () => Promise<typeof MapLine>;
	"MapLineSeries": () => Promise<typeof MapLineSeries>;
	"MapPointSeries": () => Promise<typeof MapPointSeries>;
	"MapPolygon": () => Promise<typeof MapPolygon>;
	"MapPolygonSeries": () => Promise<typeof MapPolygonSeries>;
	"MapSeries": () => Promise<typeof MapSeries>;
	"Measure": () => Promise<typeof Measure>;
	"MedianPrice": () => Promise<typeof MedianPrice>;
	"Modal": () => Promise<typeof Modal>;
	"Momentum": () => Promise<typeof Momentum>;
	"MovingAverage": () => Promise<typeof MovingAverage>;
	"MovingAverageDeviation": () => Promise<typeof MovingAverageDeviation>;
	"MovingAverageEnvelope": () => Promise<typeof MovingAverageEnvelope>;
	"OHLC": () => Promise<typeof OHLC>;
	"OHLCSeries": () => Promise<typeof OHLCSeries>;
	"OnBalanceVolume": () => Promise<typeof OnBalanceVolume>;
	"OverboughtOversold": () => Promise<typeof OverboughtOversold>;
	"PVT": () => Promise<typeof PVT>;
	"Pack": () => Promise<typeof Pack>;
	"PanelControls": () => Promise<typeof PanelControls>;
	"ParallelChannelSeries": () => Promise<typeof ParallelChannelSeries>;
	"Partition": () => Promise<typeof Partition>;
	"PathPattern": () => Promise<typeof PathPattern>;
	"Pattern": () => Promise<typeof Pattern>;
	"PatternSet": () => Promise<typeof PatternSet>;
	"PercentChart": () => Promise<typeof PercentChart>;
	"PercentSeries": () => Promise<typeof PercentSeries>;
	"PeriodSelector": () => Promise<typeof PeriodSelector>;
	"PictorialStackedSeries": () => Promise<typeof PictorialStackedSeries>;
	"Picture": () => Promise<typeof Picture>;
	"PicturePattern": () => Promise<typeof PicturePattern>;
	"PieChart": () => Promise<typeof PieChart>;
	"PieSeries": () => Promise<typeof PieSeries>;
	"PointedRectangle": () => Promise<typeof PointedRectangle>;
	"Polygon": () => Promise<typeof Polygon>;
	"PolylineSeries": () => Promise<typeof PolylineSeries>;
	"PyramidSeries": () => Promise<typeof PyramidSeries>;
	"QuadrantLineSeries": () => Promise<typeof QuadrantLineSeries>;
	"RadarChart": () => Promise<typeof RadarChart>;
	"RadarColumnSeries": () => Promise<typeof RadarColumnSeries>;
	"RadarCursor": () => Promise<typeof RadarCursor>;
	"RadarLineSeries": () => Promise<typeof RadarLineSeries>;
	"RadialGradient": () => Promise<typeof RadialGradient>;
	"RadialLabel": () => Promise<typeof RadialLabel>;
	"RadialText": () => Promise<typeof RadialText>;
	"Rectangle": () => Promise<typeof Rectangle>;
	"RectanglePattern": () => Promise<typeof RectanglePattern>;
	"RectangleSeries": () => Promise<typeof RectangleSeries>;
	"RegressionSeries": () => Promise<typeof RegressionSeries>;
	"RelativeStrengthIndex": () => Promise<typeof RelativeStrengthIndex>;
	"ResetControl": () => Promise<typeof ResetControl>;
	"RoundedRectangle": () => Promise<typeof RoundedRectangle>;
	"Sankey": () => Promise<typeof Sankey>;
	"SankeyLink": () => Promise<typeof SankeyLink>;
	"SankeyNodes": () => Promise<typeof SankeyNodes>;
	"Scrollbar": () => Promise<typeof Scrollbar>;
	"SerialChart": () => Promise<typeof SerialChart>;
	"Serializer": () => Promise<typeof Serializer>;
	"Series": () => Promise<typeof Series>;
	"SeriesTypeControl": () => Promise<typeof SeriesTypeControl>;
	"SettingsControl": () => Promise<typeof SettingsControl>;
	"SettingsModal": () => Promise<typeof SettingsModal>;
	"SimpleLineSeries": () => Promise<typeof SimpleLineSeries>;
	"Slice": () => Promise<typeof Slice>;
	"SliceGrouper": () => Promise<typeof SliceGrouper>;
	"SlicedChart": () => Promise<typeof SlicedChart>;
	"Slider": () => Promise<typeof Slider>;
	"SmoothedRadarLineSeries": () => Promise<typeof SmoothedRadarLineSeries>;
	"SmoothedXLineSeries": () => Promise<typeof SmoothedXLineSeries>;
	"SmoothedXYLineSeries": () => Promise<typeof SmoothedXYLineSeries>;
	"SmoothedYLineSeries": () => Promise<typeof SmoothedYLineSeries>;
	"Sprite": () => Promise<typeof Sprite>;
	"SpriteResizer": () => Promise<typeof SpriteResizer>;
	"StandardDeviation": () => Promise<typeof StandardDeviation>;
	"Star": () => Promise<typeof Star>;
	"StepLineSeries": () => Promise<typeof StepLineSeries>;
	"StochasticMomentumIndex": () => Promise<typeof StochasticMomentumIndex>;
	"StochasticOscillator": () => Promise<typeof StochasticOscillator>;
	"StockChart": () => Promise<typeof StockChart>;
	"StockControl": () => Promise<typeof StockControl>;
	"StockLegend": () => Promise<typeof StockLegend>;
	"StockPanel": () => Promise<typeof StockPanel>;
	"StockToolbar": () => Promise<typeof StockToolbar>;
	"Sunburst": () => Promise<typeof Sunburst>;
	"Text": () => Promise<typeof Text>;
	"Tick": () => Promise<typeof Tick>;
	"Tooltip": () => Promise<typeof Tooltip>;
	"Tree": () => Promise<typeof Tree>;
	"Treemap": () => Promise<typeof Treemap>;
	"TrendLineSeries": () => Promise<typeof TrendLineSeries>;
	"Triangle": () => Promise<typeof Triangle>;
	"Trix": () => Promise<typeof Trix>;
	"TypicalPrice": () => Promise<typeof TypicalPrice>;
	"VWAP": () => Promise<typeof VWAP>;
	"ValueAxis": () => Promise<typeof ValueAxis>;
	"Venn": () => Promise<typeof Venn>;
	"VerticalLayout": () => Promise<typeof VerticalLayout>;
	"VerticalLineSeries": () => Promise<typeof VerticalLineSeries>;
	"Volume": () => Promise<typeof Volume>;
	"VolumeProfile": () => Promise<typeof VolumeProfile>;
	"VoronoiTreemap": () => Promise<typeof VoronoiTreemap>;
	"WilliamsR": () => Promise<typeof WilliamsR>;
	"WordCloud": () => Promise<typeof WordCloud>;
	"XYChart": () => Promise<typeof XYChart>;
	"XYChartScrollbar": () => Promise<typeof XYChartScrollbar>;
	"XYCursor": () => Promise<typeof XYCursor>;
	"XYSeries": () => Promise<typeof XYSeries>;
	"ZigZag": () => Promise<typeof ZigZag>;
	"ZoomControl": () => Promise<typeof ZoomControl>;
	"ZoomTools": () => Promise<typeof ZoomTools>;
	"ZoomableContainer": () => Promise<typeof ZoomableContainer>;
}

const classes: IClasses = {
	"AccelerationBands": () => import(/* webpackExports: "AccelerationBands", webpackChunkName: "json_stock" */ "./../../../stock").then((m) => m.AccelerationBands),
	"AccumulationDistribution": () => import(/* webpackExports: "AccumulationDistribution", webpackChunkName: "json_stock" */ "./../../../stock").then((m) => m.AccumulationDistribution),
	"AccumulativeSwingIndex": () => import(/* webpackExports: "AccumulativeSwingIndex", webpackChunkName: "json_stock" */ "./../../../stock").then((m) => m.AccumulativeSwingIndex),
	"Annotator": () => import(/* webpackExports: "Annotator", webpackChunkName: "json_plugins_exporting" */ "./../../../plugins/exporting").then((m) => m.Annotator),
	"ArcDiagram": () => import(/* webpackExports: "ArcDiagram", webpackChunkName: "json_flow" */ "./../../../flow").then((m) => m.ArcDiagram),
	"ArcDiagramLink": () => import(/* webpackExports: "ArcDiagramLink", webpackChunkName: "json_flow" */ "./../../../flow").then((m) => m.ArcDiagramLink),
	"ArcDiagramNodes": () => import(/* webpackExports: "ArcDiagramNodes", webpackChunkName: "json_flow" */ "./../../../flow").then((m) => m.ArcDiagramNodes),
	"Aroon": () => import(/* webpackExports: "Aroon", webpackChunkName: "json_stock" */ "./../../../stock").then((m) => m.Aroon),
	"AverageSeries": () => import(/* webpackExports: "AverageSeries", webpackChunkName: "json_stock" */ "./../../../stock").then((m) => m.AverageSeries),
	"AverageTrueRange": () => import(/* webpackExports: "AverageTrueRange", webpackChunkName: "json_stock" */ "./../../../stock").then((m) => m.AverageTrueRange),
	"AwesomeOscillator": () => import(/* webpackExports: "AwesomeOscillator", webpackChunkName: "json_stock" */ "./../../../stock").then((m) => m.AwesomeOscillator),
	"Axis": () => import(/* webpackExports: "Axis", webpackChunkName: "json_xy" */ "./../../../xy").then((m) => m.Axis),
	"AxisBullet": () => import(/* webpackExports: "AxisBullet", webpackChunkName: "json_xy" */ "./../../../xy").then((m) => m.AxisBullet),
	"AxisLabel": () => import(/* webpackExports: "AxisLabel", webpackChunkName: "json_xy" */ "./../../../xy").then((m) => m.AxisLabel),
	"AxisLabelRadial": () => import(/* webpackExports: "AxisLabelRadial", webpackChunkName: "json_xy" */ "./../../../xy").then((m) => m.AxisLabelRadial),
	"AxisRenderer": () => import(/* webpackExports: "AxisRenderer", webpackChunkName: "json_xy" */ "./../../../xy").then((m) => m.AxisRenderer),
	"AxisRendererCircular": () => import(/* webpackExports: "AxisRendererCircular", webpackChunkName: "json_radar" */ "./../../../radar").then((m) => m.AxisRendererCircular),
	"AxisRendererRadial": () => import(/* webpackExports: "AxisRendererRadial", webpackChunkName: "json_radar" */ "./../../../radar").then((m) => m.AxisRendererRadial),
	"AxisRendererX": () => import(/* webpackExports: "AxisRendererX", webpackChunkName: "json_xy" */ "./../../../xy").then((m) => m.AxisRendererX),
	"AxisRendererY": () => import(/* webpackExports: "AxisRendererY", webpackChunkName: "json_xy" */ "./../../../xy").then((m) => m.AxisRendererY),
	"AxisTick": () => import(/* webpackExports: "AxisTick", webpackChunkName: "json_xy" */ "./../../../xy").then((m) => m.AxisTick),
	"BaseColumnSeries": () => import(/* webpackExports: "BaseColumnSeries", webpackChunkName: "json_xy" */ "./../../../xy").then((m) => m.BaseColumnSeries),
	"BollingerBands": () => import(/* webpackExports: "BollingerBands", webpackChunkName: "json_stock" */ "./../../../stock").then((m) => m.BollingerBands),
	"BreadcrumbBar": () => import(/* webpackExports: "BreadcrumbBar", webpackChunkName: "json_hierarchy" */ "./../../../hierarchy").then((m) => m.BreadcrumbBar),
	"BullBearPower": () => import(/* webpackExports: "BullBearPower", webpackChunkName: "json_stock" */ "./../../../stock").then((m) => m.BullBearPower),
	"Bullet": () => import(/* webpackExports: "Bullet", webpackChunkName: "json_index" */ "./../../../index").then((m) => m.Bullet),
	"Button": () => import(/* webpackExports: "Button", webpackChunkName: "json_index" */ "./../../../index").then((m) => m.Button),
	"CalloutSeries": () => import(/* webpackExports: "CalloutSeries", webpackChunkName: "json_stock" */ "./../../../stock").then((m) => m.CalloutSeries),
	"Candlestick": () => import(/* webpackExports: "Candlestick", webpackChunkName: "json_xy" */ "./../../../xy").then((m) => m.Candlestick),
	"CandlestickSeries": () => import(/* webpackExports: "CandlestickSeries", webpackChunkName: "json_xy" */ "./../../../xy").then((m) => m.CandlestickSeries),
	"CategoryAxis": () => import(/* webpackExports: "CategoryAxis", webpackChunkName: "json_xy" */ "./../../../xy").then((m) => m.CategoryAxis),
	"CategoryDateAxis": () => import(/* webpackExports: "CategoryDateAxis", webpackChunkName: "json_xy" */ "./../../../xy").then((m) => m.CategoryDateAxis),
	"ChaikinMoneyFlow": () => import(/* webpackExports: "ChaikinMoneyFlow", webpackChunkName: "json_stock" */ "./../../../stock").then((m) => m.ChaikinMoneyFlow),
	"ChaikinOscillator": () => import(/* webpackExports: "ChaikinOscillator", webpackChunkName: "json_stock" */ "./../../../stock").then((m) => m.ChaikinOscillator),
	"Chart": () => import(/* webpackExports: "Chart", webpackChunkName: "json_index" */ "./../../../index").then((m) => m.Chart),
	"ChartIndicator": () => import(/* webpackExports: "ChartIndicator", webpackChunkName: "json_stock" */ "./../../../stock").then((m) => m.ChartIndicator),
	"Chord": () => import(/* webpackExports: "Chord", webpackChunkName: "json_flow" */ "./../../../flow").then((m) => m.Chord),
	"ChordDirected": () => import(/* webpackExports: "ChordDirected", webpackChunkName: "json_flow" */ "./../../../flow").then((m) => m.ChordDirected),
	"ChordLink": () => import(/* webpackExports: "ChordLink", webpackChunkName: "json_flow" */ "./../../../flow").then((m) => m.ChordLink),
	"ChordLinkDirected": () => import(/* webpackExports: "ChordLinkDirected", webpackChunkName: "json_flow" */ "./../../../flow").then((m) => m.ChordLinkDirected),
	"ChordNodes": () => import(/* webpackExports: "ChordNodes", webpackChunkName: "json_flow" */ "./../../../flow").then((m) => m.ChordNodes),
	"ChordNonRibbon": () => import(/* webpackExports: "ChordNonRibbon", webpackChunkName: "json_flow" */ "./../../../flow").then((m) => m.ChordNonRibbon),
	"Circle": () => import(/* webpackExports: "Circle", webpackChunkName: "json_index" */ "./../../../index").then((m) => m.Circle),
	"CirclePattern": () => import(/* webpackExports: "CirclePattern", webpackChunkName: "json_index" */ "./../../../index").then((m) => m.CirclePattern),
	"ClockHand": () => import(/* webpackExports: "ClockHand", webpackChunkName: "json_radar" */ "./../../../radar").then((m) => m.ClockHand),
	"ClusteredPointSeries": () => import(/* webpackExports: "ClusteredPointSeries", webpackChunkName: "json_map" */ "./../../../map").then((m) => m.ClusteredPointSeries),
	"ColorControl": () => import(/* webpackExports: "ColorControl", webpackChunkName: "json_stock" */ "./../../../stock").then((m) => m.ColorControl),
	"ColorSet": () => import(/* webpackExports: "ColorSet", webpackChunkName: "json_index" */ "./../../../index").then((m) => m.ColorSet),
	"ColumnSeries": () => import(/* webpackExports: "ColumnSeries", webpackChunkName: "json_xy" */ "./../../../xy").then((m) => m.ColumnSeries),
	"CommodityChannelIndex": () => import(/* webpackExports: "CommodityChannelIndex", webpackChunkName: "json_stock" */ "./../../../stock").then((m) => m.CommodityChannelIndex),
	"ComparisonControl": () => import(/* webpackExports: "ComparisonControl", webpackChunkName: "json_stock" */ "./../../../stock").then((m) => m.ComparisonControl),
	"Component": () => import(/* webpackExports: "Component", webpackChunkName: "json_index" */ "./../../../index").then((m) => m.Component),
	"Container": () => import(/* webpackExports: "Container", webpackChunkName: "json_index" */ "./../../../index").then((m) => m.Container),
	"DataSaveControl": () => import(/* webpackExports: "DataSaveControl", webpackChunkName: "json_stock" */ "./../../../stock").then((m) => m.DataSaveControl),
	"DateAxis": () => import(/* webpackExports: "DateAxis", webpackChunkName: "json_xy" */ "./../../../xy").then((m) => m.DateAxis),
	"DateRangeSelector": () => import(/* webpackExports: "DateRangeSelector", webpackChunkName: "json_stock" */ "./../../../stock").then((m) => m.DateRangeSelector),
	"DisparityIndex": () => import(/* webpackExports: "DisparityIndex", webpackChunkName: "json_stock" */ "./../../../stock").then((m) => m.DisparityIndex),
	"DoodleSeries": () => import(/* webpackExports: "DoodleSeries", webpackChunkName: "json_stock" */ "./../../../stock").then((m) => m.DoodleSeries),
	"DrawingControl": () => import(/* webpackExports: "DrawingControl", webpackChunkName: "json_stock" */ "./../../../stock").then((m) => m.DrawingControl),
	"DrawingSeries": () => import(/* webpackExports: "DrawingSeries", webpackChunkName: "json_stock" */ "./../../../stock").then((m) => m.DrawingSeries),
	"DrawingToolControl": () => import(/* webpackExports: "DrawingToolControl", webpackChunkName: "json_stock" */ "./../../../stock").then((m) => m.DrawingToolControl),
	"Dropdown": () => import(/* webpackExports: "Dropdown", webpackChunkName: "json_stock" */ "./../../../stock").then((m) => m.Dropdown),
	"DropdownColors": () => import(/* webpackExports: "DropdownColors", webpackChunkName: "json_stock" */ "./../../../stock").then((m) => m.DropdownColors),
	"DropdownControl": () => import(/* webpackExports: "DropdownControl", webpackChunkName: "json_stock" */ "./../../../stock").then((m) => m.DropdownControl),
	"DropdownList": () => import(/* webpackExports: "DropdownList", webpackChunkName: "json_stock" */ "./../../../stock").then((m) => m.DropdownList),
	"DropdownListControl": () => import(/* webpackExports: "DropdownListControl", webpackChunkName: "json_stock" */ "./../../../stock").then((m) => m.DropdownListControl),
	"DurationAxis": () => import(/* webpackExports: "DurationAxis", webpackChunkName: "json_xy" */ "./../../../xy").then((m) => m.DurationAxis),
	"EditableLabel": () => import(/* webpackExports: "EditableLabel", webpackChunkName: "json_index" */ "./../../../index").then((m) => m.EditableLabel),
	"Ellipse": () => import(/* webpackExports: "Ellipse", webpackChunkName: "json_index" */ "./../../../index").then((m) => m.Ellipse),
	"EllipseSeries": () => import(/* webpackExports: "EllipseSeries", webpackChunkName: "json_stock" */ "./../../../stock").then((m) => m.EllipseSeries),
	"Entity": () => import(/* webpackExports: "Entity", webpackChunkName: "json_index" */ "./../../../index").then((m) => m.Entity),
	"Exporting": () => import(/* webpackExports: "Exporting", webpackChunkName: "json_plugins_exporting" */ "./../../../plugins/exporting").then((m) => m.Exporting),
	"ExportingMenu": () => import(/* webpackExports: "ExportingMenu", webpackChunkName: "json_plugins_exporting" */ "./../../../plugins/exporting").then((m) => m.ExportingMenu),
	"FibonacciSeries": () => import(/* webpackExports: "FibonacciSeries", webpackChunkName: "json_stock" */ "./../../../stock").then((m) => m.FibonacciSeries),
	"FibonacciTimezoneSeries": () => import(/* webpackExports: "FibonacciTimezoneSeries", webpackChunkName: "json_stock" */ "./../../../stock").then((m) => m.FibonacciTimezoneSeries),
	"Flow": () => import(/* webpackExports: "Flow", webpackChunkName: "json_flow" */ "./../../../flow").then((m) => m.Flow),
	"FlowLink": () => import(/* webpackExports: "FlowLink", webpackChunkName: "json_flow" */ "./../../../flow").then((m) => m.FlowLink),
	"FlowNode": () => import(/* webpackExports: "FlowNode", webpackChunkName: "json_flow" */ "./../../../flow").then((m) => m.FlowNode),
	"FlowNodes": () => import(/* webpackExports: "FlowNodes", webpackChunkName: "json_flow" */ "./../../../flow").then((m) => m.FlowNodes),
	"ForceDirected": () => import(/* webpackExports: "ForceDirected", webpackChunkName: "json_hierarchy" */ "./../../../hierarchy").then((m) => m.ForceDirected),
	"FunnelSeries": () => import(/* webpackExports: "FunnelSeries", webpackChunkName: "json_percent" */ "./../../../percent").then((m) => m.FunnelSeries),
	"FunnelSlice": () => import(/* webpackExports: "FunnelSlice", webpackChunkName: "json_percent" */ "./../../../percent").then((m) => m.FunnelSlice),
	"GaplessDateAxis": () => import(/* webpackExports: "GaplessDateAxis", webpackChunkName: "json_xy" */ "./../../../xy").then((m) => m.GaplessDateAxis),
	"Gradient": () => import(/* webpackExports: "Gradient", webpackChunkName: "json_index" */ "./../../../index").then((m) => m.Gradient),
	"GrainPattern": () => import(/* webpackExports: "GrainPattern", webpackChunkName: "json_index" */ "./../../../index").then((m) => m.GrainPattern),
	"Graphics": () => import(/* webpackExports: "Graphics", webpackChunkName: "json_index" */ "./../../../index").then((m) => m.Graphics),
	"GraticuleSeries": () => import(/* webpackExports: "GraticuleSeries", webpackChunkName: "json_map" */ "./../../../map").then((m) => m.GraticuleSeries),
	"Grid": () => import(/* webpackExports: "Grid", webpackChunkName: "json_xy" */ "./../../../xy").then((m) => m.Grid),
	"GridLayout": () => import(/* webpackExports: "GridLayout", webpackChunkName: "json_index" */ "./../../../index").then((m) => m.GridLayout),
	"HeatLegend": () => import(/* webpackExports: "HeatLegend", webpackChunkName: "json_index" */ "./../../../index").then((m) => m.HeatLegend),
	"Hierarchy": () => import(/* webpackExports: "Hierarchy", webpackChunkName: "json_hierarchy" */ "./../../../hierarchy").then((m) => m.Hierarchy),
	"HierarchyLink": () => import(/* webpackExports: "HierarchyLink", webpackChunkName: "json_hierarchy" */ "./../../../hierarchy").then((m) => m.HierarchyLink),
	"HierarchyNode": () => import(/* webpackExports: "HierarchyNode", webpackChunkName: "json_hierarchy" */ "./../../../hierarchy").then((m) => m.HierarchyNode),
	"HorizontalLayout": () => import(/* webpackExports: "HorizontalLayout", webpackChunkName: "json_index" */ "./../../../index").then((m) => m.HorizontalLayout),
	"HorizontalLineSeries": () => import(/* webpackExports: "HorizontalLineSeries", webpackChunkName: "json_stock" */ "./../../../stock").then((m) => m.HorizontalLineSeries),
	"HorizontalRaySeries": () => import(/* webpackExports: "HorizontalRaySeries", webpackChunkName: "json_stock" */ "./../../../stock").then((m) => m.HorizontalRaySeries),
	"IconControl": () => import(/* webpackExports: "IconControl", webpackChunkName: "json_stock" */ "./../../../stock").then((m) => m.IconControl),
	"IconSeries": () => import(/* webpackExports: "IconSeries", webpackChunkName: "json_stock" */ "./../../../stock").then((m) => m.IconSeries),
	"Indicator": () => import(/* webpackExports: "Indicator", webpackChunkName: "json_stock" */ "./../../../stock").then((m) => m.Indicator),
	"IndicatorControl": () => import(/* webpackExports: "IndicatorControl", webpackChunkName: "json_stock" */ "./../../../stock").then((m) => m.IndicatorControl),
	"InterfaceColors": () => import(/* webpackExports: "InterfaceColors", webpackChunkName: "json_index" */ "./../../../index").then((m) => m.InterfaceColors),
	"IntervalControl": () => import(/* webpackExports: "IntervalControl", webpackChunkName: "json_stock" */ "./../../../stock").then((m) => m.IntervalControl),
	"Label": () => import(/* webpackExports: "Label", webpackChunkName: "json_index" */ "./../../../index").then((m) => m.Label),
	"LabelSeries": () => import(/* webpackExports: "LabelSeries", webpackChunkName: "json_stock" */ "./../../../stock").then((m) => m.LabelSeries),
	"Layout": () => import(/* webpackExports: "Layout", webpackChunkName: "json_index" */ "./../../../index").then((m) => m.Layout),
	"Legend": () => import(/* webpackExports: "Legend", webpackChunkName: "json_index" */ "./../../../index").then((m) => m.Legend),
	"Line": () => import(/* webpackExports: "Line", webpackChunkName: "json_index" */ "./../../../index").then((m) => m.Line),
	"LinePattern": () => import(/* webpackExports: "LinePattern", webpackChunkName: "json_index" */ "./../../../index").then((m) => m.LinePattern),
	"LineSeries": () => import(/* webpackExports: "LineSeries", webpackChunkName: "json_xy" */ "./../../../xy").then((m) => m.LineSeries),
	"LinearGradient": () => import(/* webpackExports: "LinearGradient", webpackChunkName: "json_index" */ "./../../../index").then((m) => m.LinearGradient),
	"LinkedHierarchy": () => import(/* webpackExports: "LinkedHierarchy", webpackChunkName: "json_hierarchy" */ "./../../../hierarchy").then((m) => m.LinkedHierarchy),
	"LinkedHierarchyNode": () => import(/* webpackExports: "LinkedHierarchyNode", webpackChunkName: "json_hierarchy" */ "./../../../hierarchy").then((m) => m.LinkedHierarchyNode),
	"MACD": () => import(/* webpackExports: "MACD", webpackChunkName: "json_stock" */ "./../../../stock").then((m) => m.MACD),
	"MACross": () => import(/* webpackExports: "MACross", webpackChunkName: "json_stock" */ "./../../../stock").then((m) => m.MACross),
	"MapChart": () => import(/* webpackExports: "MapChart", webpackChunkName: "json_map" */ "./../../../map").then((m) => m.MapChart),
	"MapLine": () => import(/* webpackExports: "MapLine", webpackChunkName: "json_map" */ "./../../../map").then((m) => m.MapLine),
	"MapLineSeries": () => import(/* webpackExports: "MapLineSeries", webpackChunkName: "json_map" */ "./../../../map").then((m) => m.MapLineSeries),
	"MapPointSeries": () => import(/* webpackExports: "MapPointSeries", webpackChunkName: "json_map" */ "./../../../map").then((m) => m.MapPointSeries),
	"MapPolygon": () => import(/* webpackExports: "MapPolygon", webpackChunkName: "json_map" */ "./../../../map").then((m) => m.MapPolygon),
	"MapPolygonSeries": () => import(/* webpackExports: "MapPolygonSeries", webpackChunkName: "json_map" */ "./../../../map").then((m) => m.MapPolygonSeries),
	"MapSeries": () => import(/* webpackExports: "MapSeries", webpackChunkName: "json_map" */ "./../../../map").then((m) => m.MapSeries),
	"Measure": () => import(/* webpackExports: "Measure", webpackChunkName: "json_stock" */ "./../../../stock").then((m) => m.Measure),
	"MedianPrice": () => import(/* webpackExports: "MedianPrice", webpackChunkName: "json_stock" */ "./../../../stock").then((m) => m.MedianPrice),
	"Modal": () => import(/* webpackExports: "Modal", webpackChunkName: "json_index" */ "./../../../index").then((m) => m.Modal),
	"Momentum": () => import(/* webpackExports: "Momentum", webpackChunkName: "json_stock" */ "./../../../stock").then((m) => m.Momentum),
	"MovingAverage": () => import(/* webpackExports: "MovingAverage", webpackChunkName: "json_stock" */ "./../../../stock").then((m) => m.MovingAverage),
	"MovingAverageDeviation": () => import(/* webpackExports: "MovingAverageDeviation", webpackChunkName: "json_stock" */ "./../../../stock").then((m) => m.MovingAverageDeviation),
	"MovingAverageEnvelope": () => import(/* webpackExports: "MovingAverageEnvelope", webpackChunkName: "json_stock" */ "./../../../stock").then((m) => m.MovingAverageEnvelope),
	"OHLC": () => import(/* webpackExports: "OHLC", webpackChunkName: "json_xy" */ "./../../../xy").then((m) => m.OHLC),
	"OHLCSeries": () => import(/* webpackExports: "OHLCSeries", webpackChunkName: "json_xy" */ "./../../../xy").then((m) => m.OHLCSeries),
	"OnBalanceVolume": () => import(/* webpackExports: "OnBalanceVolume", webpackChunkName: "json_stock" */ "./../../../stock").then((m) => m.OnBalanceVolume),
	"OverboughtOversold": () => import(/* webpackExports: "OverboughtOversold", webpackChunkName: "json_stock" */ "./../../../stock").then((m) => m.OverboughtOversold),
	"PVT": () => import(/* webpackExports: "PVT", webpackChunkName: "json_stock" */ "./../../../stock").then((m) => m.PVT),
	"Pack": () => import(/* webpackExports: "Pack", webpackChunkName: "json_hierarchy" */ "./../../../hierarchy").then((m) => m.Pack),
	"PanelControls": () => import(/* webpackExports: "PanelControls", webpackChunkName: "json_stock" */ "./../../../stock").then((m) => m.PanelControls),
	"ParallelChannelSeries": () => import(/* webpackExports: "ParallelChannelSeries", webpackChunkName: "json_stock" */ "./../../../stock").then((m) => m.ParallelChannelSeries),
	"Partition": () => import(/* webpackExports: "Partition", webpackChunkName: "json_hierarchy" */ "./../../../hierarchy").then((m) => m.Partition),
	"PathPattern": () => import(/* webpackExports: "PathPattern", webpackChunkName: "json_index" */ "./../../../index").then((m) => m.PathPattern),
	"Pattern": () => import(/* webpackExports: "Pattern", webpackChunkName: "json_index" */ "./../../../index").then((m) => m.Pattern),
	"PatternSet": () => import(/* webpackExports: "PatternSet", webpackChunkName: "json_index" */ "./../../../index").then((m) => m.PatternSet),
	"PercentChart": () => import(/* webpackExports: "PercentChart", webpackChunkName: "json_percent" */ "./../../../percent").then((m) => m.PercentChart),
	"PercentSeries": () => import(/* webpackExports: "PercentSeries", webpackChunkName: "json_percent" */ "./../../../percent").then((m) => m.PercentSeries),
	"PeriodSelector": () => import(/* webpackExports: "PeriodSelector", webpackChunkName: "json_stock" */ "./../../../stock").then((m) => m.PeriodSelector),
	"PictorialStackedSeries": () => import(/* webpackExports: "PictorialStackedSeries", webpackChunkName: "json_percent" */ "./../../../percent").then((m) => m.PictorialStackedSeries),
	"Picture": () => import(/* webpackExports: "Picture", webpackChunkName: "json_index" */ "./../../../index").then((m) => m.Picture),
	"PicturePattern": () => import(/* webpackExports: "PicturePattern", webpackChunkName: "json_index" */ "./../../../index").then((m) => m.PicturePattern),
	"PieChart": () => import(/* webpackExports: "PieChart", webpackChunkName: "json_percent" */ "./../../../percent").then((m) => m.PieChart),
	"PieSeries": () => import(/* webpackExports: "PieSeries", webpackChunkName: "json_percent" */ "./../../../percent").then((m) => m.PieSeries),
	"PointedRectangle": () => import(/* webpackExports: "PointedRectangle", webpackChunkName: "json_index" */ "./../../../index").then((m) => m.PointedRectangle),
	"Polygon": () => import(/* webpackExports: "Polygon", webpackChunkName: "json_index" */ "./../../../index").then((m) => m.Polygon),
	"PolylineSeries": () => import(/* webpackExports: "PolylineSeries", webpackChunkName: "json_stock" */ "./../../../stock").then((m) => m.PolylineSeries),
	"PyramidSeries": () => import(/* webpackExports: "PyramidSeries", webpackChunkName: "json_percent" */ "./../../../percent").then((m) => m.PyramidSeries),
	"QuadrantLineSeries": () => import(/* webpackExports: "QuadrantLineSeries", webpackChunkName: "json_stock" */ "./../../../stock").then((m) => m.QuadrantLineSeries),
	"RadarChart": () => import(/* webpackExports: "RadarChart", webpackChunkName: "json_radar" */ "./../../../radar").then((m) => m.RadarChart),
	"RadarColumnSeries": () => import(/* webpackExports: "RadarColumnSeries", webpackChunkName: "json_radar" */ "./../../../radar").then((m) => m.RadarColumnSeries),
	"RadarCursor": () => import(/* webpackExports: "RadarCursor", webpackChunkName: "json_radar" */ "./../../../radar").then((m) => m.RadarCursor),
	"RadarLineSeries": () => import(/* webpackExports: "RadarLineSeries", webpackChunkName: "json_radar" */ "./../../../radar").then((m) => m.RadarLineSeries),
	"RadialGradient": () => import(/* webpackExports: "RadialGradient", webpackChunkName: "json_index" */ "./../../../index").then((m) => m.RadialGradient),
	"RadialLabel": () => import(/* webpackExports: "RadialLabel", webpackChunkName: "json_index" */ "./../../../index").then((m) => m.RadialLabel),
	"RadialText": () => import(/* webpackExports: "RadialText", webpackChunkName: "json_index" */ "./../../../index").then((m) => m.RadialText),
	"Rectangle": () => import(/* webpackExports: "Rectangle", webpackChunkName: "json_index" */ "./../../../index").then((m) => m.Rectangle),
	"RectanglePattern": () => import(/* webpackExports: "RectanglePattern", webpackChunkName: "json_index" */ "./../../../index").then((m) => m.RectanglePattern),
	"RectangleSeries": () => import(/* webpackExports: "RectangleSeries", webpackChunkName: "json_stock" */ "./../../../stock").then((m) => m.RectangleSeries),
	"RegressionSeries": () => import(/* webpackExports: "RegressionSeries", webpackChunkName: "json_stock" */ "./../../../stock").then((m) => m.RegressionSeries),
	"RelativeStrengthIndex": () => import(/* webpackExports: "RelativeStrengthIndex", webpackChunkName: "json_stock" */ "./../../../stock").then((m) => m.RelativeStrengthIndex),
	"ResetControl": () => import(/* webpackExports: "ResetControl", webpackChunkName: "json_stock" */ "./../../../stock").then((m) => m.ResetControl),
	"RoundedRectangle": () => import(/* webpackExports: "RoundedRectangle", webpackChunkName: "json_index" */ "./../../../index").then((m) => m.RoundedRectangle),
	"Sankey": () => import(/* webpackExports: "Sankey", webpackChunkName: "json_flow" */ "./../../../flow").then((m) => m.Sankey),
	"SankeyLink": () => import(/* webpackExports: "SankeyLink", webpackChunkName: "json_flow" */ "./../../../flow").then((m) => m.SankeyLink),
	"SankeyNodes": () => import(/* webpackExports: "SankeyNodes", webpackChunkName: "json_flow" */ "./../../../flow").then((m) => m.SankeyNodes),
	"Scrollbar": () => import(/* webpackExports: "Scrollbar", webpackChunkName: "json_index" */ "./../../../index").then((m) => m.Scrollbar),
	"SerialChart": () => import(/* webpackExports: "SerialChart", webpackChunkName: "json_index" */ "./../../../index").then((m) => m.SerialChart),
	"Serializer": () => import(/* webpackExports: "Serializer", webpackChunkName: "json_plugins_json" */ "./../../../plugins/json").then((m) => m.Serializer),
	"Series": () => import(/* webpackExports: "Series", webpackChunkName: "json_index" */ "./../../../index").then((m) => m.Series),
	"SeriesTypeControl": () => import(/* webpackExports: "SeriesTypeControl", webpackChunkName: "json_stock" */ "./../../../stock").then((m) => m.SeriesTypeControl),
	"SettingsControl": () => import(/* webpackExports: "SettingsControl", webpackChunkName: "json_stock" */ "./../../../stock").then((m) => m.SettingsControl),
	"SettingsModal": () => import(/* webpackExports: "SettingsModal", webpackChunkName: "json_stock" */ "./../../../stock").then((m) => m.SettingsModal),
	"SimpleLineSeries": () => import(/* webpackExports: "SimpleLineSeries", webpackChunkName: "json_stock" */ "./../../../stock").then((m) => m.SimpleLineSeries),
	"Slice": () => import(/* webpackExports: "Slice", webpackChunkName: "json_index" */ "./../../../index").then((m) => m.Slice),
	"SliceGrouper": () => import(/* webpackExports: "SliceGrouper", webpackChunkName: "json_plugins_sliceGrouper" */ "./../../../plugins/sliceGrouper").then((m) => m.SliceGrouper),
	"SlicedChart": () => import(/* webpackExports: "SlicedChart", webpackChunkName: "json_percent" */ "./../../../percent").then((m) => m.SlicedChart),
	"Slider": () => import(/* webpackExports: "Slider", webpackChunkName: "json_index" */ "./../../../index").then((m) => m.Slider),
	"SmoothedRadarLineSeries": () => import(/* webpackExports: "SmoothedRadarLineSeries", webpackChunkName: "json_radar" */ "./../../../radar").then((m) => m.SmoothedRadarLineSeries),
	"SmoothedXLineSeries": () => import(/* webpackExports: "SmoothedXLineSeries", webpackChunkName: "json_xy" */ "./../../../xy").then((m) => m.SmoothedXLineSeries),
	"SmoothedXYLineSeries": () => import(/* webpackExports: "SmoothedXYLineSeries", webpackChunkName: "json_xy" */ "./../../../xy").then((m) => m.SmoothedXYLineSeries),
	"SmoothedYLineSeries": () => import(/* webpackExports: "SmoothedYLineSeries", webpackChunkName: "json_xy" */ "./../../../xy").then((m) => m.SmoothedYLineSeries),
	"Sprite": () => import(/* webpackExports: "Sprite", webpackChunkName: "json_index" */ "./../../../index").then((m) => m.Sprite),
	"SpriteResizer": () => import(/* webpackExports: "SpriteResizer", webpackChunkName: "json_index" */ "./../../../index").then((m) => m.SpriteResizer),
	"StandardDeviation": () => import(/* webpackExports: "StandardDeviation", webpackChunkName: "json_stock" */ "./../../../stock").then((m) => m.StandardDeviation),
	"Star": () => import(/* webpackExports: "Star", webpackChunkName: "json_index" */ "./../../../index").then((m) => m.Star),
	"StepLineSeries": () => import(/* webpackExports: "StepLineSeries", webpackChunkName: "json_xy" */ "./../../../xy").then((m) => m.StepLineSeries),
	"StochasticMomentumIndex": () => import(/* webpackExports: "StochasticMomentumIndex", webpackChunkName: "json_stock" */ "./../../../stock").then((m) => m.StochasticMomentumIndex),
	"StochasticOscillator": () => import(/* webpackExports: "StochasticOscillator", webpackChunkName: "json_stock" */ "./../../../stock").then((m) => m.StochasticOscillator),
	"StockChart": () => import(/* webpackExports: "StockChart", webpackChunkName: "json_stock" */ "./../../../stock").then((m) => m.StockChart),
	"StockControl": () => import(/* webpackExports: "StockControl", webpackChunkName: "json_stock" */ "./../../../stock").then((m) => m.StockControl),
	"StockLegend": () => import(/* webpackExports: "StockLegend", webpackChunkName: "json_stock" */ "./../../../stock").then((m) => m.StockLegend),
	"StockPanel": () => import(/* webpackExports: "StockPanel", webpackChunkName: "json_stock" */ "./../../../stock").then((m) => m.StockPanel),
	"StockToolbar": () => import(/* webpackExports: "StockToolbar", webpackChunkName: "json_stock" */ "./../../../stock").then((m) => m.StockToolbar),
	"Sunburst": () => import(/* webpackExports: "Sunburst", webpackChunkName: "json_hierarchy" */ "./../../../hierarchy").then((m) => m.Sunburst),
	"Text": () => import(/* webpackExports: "Text", webpackChunkName: "json_index" */ "./../../../index").then((m) => m.Text),
	"Tick": () => import(/* webpackExports: "Tick", webpackChunkName: "json_index" */ "./../../../index").then((m) => m.Tick),
	"Tooltip": () => import(/* webpackExports: "Tooltip", webpackChunkName: "json_index" */ "./../../../index").then((m) => m.Tooltip),
	"Tree": () => import(/* webpackExports: "Tree", webpackChunkName: "json_hierarchy" */ "./../../../hierarchy").then((m) => m.Tree),
	"Treemap": () => import(/* webpackExports: "Treemap", webpackChunkName: "json_hierarchy" */ "./../../../hierarchy").then((m) => m.Treemap),
	"TrendLineSeries": () => import(/* webpackExports: "TrendLineSeries", webpackChunkName: "json_stock" */ "./../../../stock").then((m) => m.TrendLineSeries),
	"Triangle": () => import(/* webpackExports: "Triangle", webpackChunkName: "json_index" */ "./../../../index").then((m) => m.Triangle),
	"Trix": () => import(/* webpackExports: "Trix", webpackChunkName: "json_stock" */ "./../../../stock").then((m) => m.Trix),
	"TypicalPrice": () => import(/* webpackExports: "TypicalPrice", webpackChunkName: "json_stock" */ "./../../../stock").then((m) => m.TypicalPrice),
	"VWAP": () => import(/* webpackExports: "VWAP", webpackChunkName: "json_stock" */ "./../../../stock").then((m) => m.VWAP),
	"ValueAxis": () => import(/* webpackExports: "ValueAxis", webpackChunkName: "json_xy" */ "./../../../xy").then((m) => m.ValueAxis),
	"Venn": () => import(/* webpackExports: "Venn", webpackChunkName: "json_venn" */ "./../../../venn").then((m) => m.Venn),
	"VerticalLayout": () => import(/* webpackExports: "VerticalLayout", webpackChunkName: "json_index" */ "./../../../index").then((m) => m.VerticalLayout),
	"VerticalLineSeries": () => import(/* webpackExports: "VerticalLineSeries", webpackChunkName: "json_stock" */ "./../../../stock").then((m) => m.VerticalLineSeries),
	"Volume": () => import(/* webpackExports: "Volume", webpackChunkName: "json_stock" */ "./../../../stock").then((m) => m.Volume),
	"VolumeProfile": () => import(/* webpackExports: "VolumeProfile", webpackChunkName: "json_stock" */ "./../../../stock").then((m) => m.VolumeProfile),
	"VoronoiTreemap": () => import(/* webpackExports: "VoronoiTreemap", webpackChunkName: "json_hierarchy" */ "./../../../hierarchy").then((m) => m.VoronoiTreemap),
	"WilliamsR": () => import(/* webpackExports: "WilliamsR", webpackChunkName: "json_stock" */ "./../../../stock").then((m) => m.WilliamsR),
	"WordCloud": () => import(/* webpackExports: "WordCloud", webpackChunkName: "json_wc" */ "./../../../wc").then((m) => m.WordCloud),
	"XYChart": () => import(/* webpackExports: "XYChart", webpackChunkName: "json_xy" */ "./../../../xy").then((m) => m.XYChart),
	"XYChartScrollbar": () => import(/* webpackExports: "XYChartScrollbar", webpackChunkName: "json_xy" */ "./../../../xy").then((m) => m.XYChartScrollbar),
	"XYCursor": () => import(/* webpackExports: "XYCursor", webpackChunkName: "json_xy" */ "./../../../xy").then((m) => m.XYCursor),
	"XYSeries": () => import(/* webpackExports: "XYSeries", webpackChunkName: "json_xy" */ "./../../../xy").then((m) => m.XYSeries),
	"ZigZag": () => import(/* webpackExports: "ZigZag", webpackChunkName: "json_stock" */ "./../../../stock").then((m) => m.ZigZag),
	"ZoomControl": () => import(/* webpackExports: "ZoomControl", webpackChunkName: "json_map" */ "./../../../map").then((m) => m.ZoomControl),
	"ZoomTools": () => import(/* webpackExports: "ZoomTools", webpackChunkName: "json_index" */ "./../../../index").then((m) => m.ZoomTools),
	"ZoomableContainer": () => import(/* webpackExports: "ZoomableContainer", webpackChunkName: "json_index" */ "./../../../index").then((m) => m.ZoomableContainer),
};

export default classes;
