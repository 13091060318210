import * as am5 from "@amcharts/amcharts5";
import type * as am5map from "@amcharts/amcharts5/map";
import type { MapViewer } from "./MapViewer";
import type { MapSerializer } from "./MapSerializer";
import type { MapEditor } from "./MapEditor";
import type { Box } from "./ui/Box";
import type { Toolbar } from "./ui/Toolbar";
import type { TabToolbar } from "./ui/TabToolbar";
import type { PixelsButton } from "./ui/PixelsButton";
import type { PointsButton } from "./ui/PointsButton";
import type { LinesButton } from "./ui/LinesButton";
import type { BubblesButton } from "./ui/BubblesButton";
import type { SettingsButton } from "./ui/SettingsButton";
import type { ResizerButton } from "./ui/ResizerButton";
//import type { AboutButton } from "./ui/AboutButton";
import type { FillButton } from "./ui/FillButton";
import type { DataButton } from "./ui/DataButton";
import type { DownloadButton } from "./ui/DownloadButton";

import type { ListControl } from "./ui/ListControl";
//import type { AccordionControl } from "./ui/AccordionControl";
import type { PolygonListControl } from "./ui/PolygonListControl";
import type { PointListControl } from "./ui/PointListControl";
import type { LineListControl } from "./ui/LineListControl";
import type { BubbleListControl } from "./ui/BubbleListControl";
import type { LabelListControl } from "./ui/LabelListControl";
import type { PointTypeControl } from "./ui/PointTypeControl";


/**
 * @ignore
 */
export class MapEditorDefaultTheme extends am5.Theme {
	protected setupDefaultRules() {
		super.setupDefaultRules();

		//const ic = this._root.interfaceColors;
		const l = this._root.language;
		//const r = this.rule.bind(this);

		/**
		 * ========================================================================
		 * charts/percent
		 * ========================================================================
		 */

		this.ruleRaw<MapViewer>("MapViewer").setAll({
			width: am5.p100,
			height: am5.p100,
			backgroundNoise:true,

			polygonTooltipText: "{name}",
			polygonInteractive: true,
			polygonAutoZoom: true,

			lineTooltipText: "{name}",
			lineInteractive: false,
			linePointScale: 0.7,
			linePointTypeKey: "Target",

			linePointTooltipText: "{name}",
			linePointInteractive: true,

			pointScale: 0.7,
			pointMinScale: 0.2,
			pointMaxScale: 5,
			pointTypeKey: "Balloon",
			pointPinned: true,

			pointTooltipText: "{name}",
			pointInteractive: true,
			pointLabelPosition: "right",

			labelText: l.translateAny("Label"),
			labelFontSize: 1,
			labelFontSizeStep: 0.1,
			labelMinFontSize: 0.5,
			labelMaxFontSize: 10,
			labelFontWeight: "normal",
			labelTextAlign: "left",
			labelTooltipText: "{name}",
			labelInteractive: false,
			labelPinned: true,

			bubbleMinSize: 1,
			bubbleMaxSize: 200,
			bubbleType: "Circle",

			bubbleTooltipText: "{name}: {value}",
			bubbleInteractive: true,

			pixelTooltipText: "{name}",
			pixelInteractive: true,
			pixelHorizontalGap: 0,
			pixelVerticalGap: 0,

			pixelType: "Hexagon",
			pixelSize: 20,
			pixelMinSize: 8,
			pixelMaxSize: 50,

			clipGrid: true,
			clipBackground: true
		});


		this.ruleRaw<MapViewer>("MapViewer", ["light"]).setAll({
			backgroundNoiseColor: am5.color(0x000000),
			backgroundFill: am5.color(0xf1f1f1),
			backgroundFillOpacity: 1,

			polygonFill: am5.color(0xE0E0E0),
			polygonFillOpacity: 0.95,
			polygonStroke: am5.color(0xaaaaaa),
			polygonStrokeOpacity: 1,
			polygonStrokeWidth: 0.5,

			//polygonFillHover: am5.color(0x86acb5),
			polygonFillOpacityHover: 1,
			polygonStrokeHover: am5.color(0x000000),
			polygonStrokeOpacityHover: 1,

			heatMinFill: am5.color(0xffffff),
			heatMaxFill: am5.color(0x000000),
			heatNeutralFill: am5.color(0xffffff),
			heatNeutralNoise: am5.color(0x000000),

			lineStroke: am5.color(0x000000),
			lineStrokeWidth: 1,
			lineStrokeDashLength: 0,
			lineStrokeOpacity: .5,

			lineStrokeHover: am5.color(0x000000),
			lineStrokeOpacityHover: 0.8,

			linePointFill: am5.color(0xFBAD26),
			linePointStroke: am5.color(0x000000),
			linePointStrokeWidth: 0.5,
			linePointStrokeOpacity: 0.5,
			linePointFillOpacity: 1,

			linePointFillHover: am5.color(0xFBAD26),
			linePointFillOpacityHover: 1,
			linePointStrokeHover: am5.color(0x000000),
			linePointStrokeOpacityHover: 1,

			pointFill: am5.color(0xE03F35),
			pointStroke: am5.color(0x000000),
			pointStrokeWidth: 0.5,
			pointStrokeOpacity: 0.5,
			pointFillOpacity: 0.7,

			pointFillHover: am5.color(0xE03F35),
			pointFillOpacityHover: 1,
			pointStrokeHover: am5.color(0x000000),
			pointStrokeOpacityHover: 1,

			labelColor: am5.color(0x000000),
			labelOpacity: 1,
			labelColorHover: am5.color(0xB8342B),

			bubbleFillHover: am5.color(0xB8342B),
			bubbleFillOpacityHover: 1,
			bubbleStrokeHover: am5.color(0x000000),
			bubbleStrokeOpacityHover: 1,

			pixelFillOpacity: 0.8,
			pixelStroke: am5.color(0xf1f1f1),
			pixelStrokeWidth: 0.5,
			pixelStrokeOpacity: 1,

			pixelFillHover: am5.color(0x86acb5),
			pixelFillOpacityHover: 1,
			pixelStrokeHover: am5.color(0xffffff),
			pixelStrokeOpacityHover: 1,

			fillColor: am5.color(0xa6bf3b)
		});

		this.ruleRaw<MapViewer>("MapViewer", ["dark"]).setAll({
			backgroundNoiseColor: am5.color(0xaaaaaa),
			backgroundFill: am5.color(0x1A1A1A),
			backgroundFillOpacity: 1,

			polygonFill: am5.color(0x333333),
			polygonFillOpacity: 0.95,
			polygonStroke: am5.color(0x555555),
			polygonStrokeOpacity: 1,
			polygonStrokeWidth: 0.5,

			//polygonFillHover: am5.color(0x86acb5),
			polygonFillOpacityHover: 1,
			polygonStrokeHover: am5.color(0xffffff),
			polygonStrokeOpacityHover: 1,

			heatMinFill: am5.color(0x333333),
			heatMaxFill: am5.color(0xFFFFFF),
			heatNeutralFill: am5.color(0x333333),
			heatNeutralNoise: am5.color(0xffffff),

			lineStroke: am5.color(0xffffff),
			lineStrokeWidth: 1,
			lineStrokeDashLength: 0,
			lineStrokeOpacity: .5,

			lineStrokeHover: am5.color(0xffffff),
			lineStrokeOpacityHover: 1,

			linePointFill: am5.color(0xFBAD26),
			linePointStroke: am5.color(0x000000),
			linePointStrokeWidth: 0.5,
			linePointStrokeOpacity: 0.5,
			linePointFillOpacity: 1,

			linePointFillHover: am5.color(0xFBAD26),
			linePointFillOpacityHover: 1,
			linePointStrokeHover: am5.color(0x000000),
			linePointStrokeOpacityHover: 1,

			pointFill: am5.color(0xE03F35),
			pointStroke: am5.color(0x000000),
			pointStrokeWidth: 0.5,
			pointStrokeOpacity: 0.5,
			pointFillOpacity: 0.7,

			pointFillHover: am5.color(0xB8342B),
			pointFillOpacityHover: 1,
			pointStrokeHover: am5.color(0x000000),
			pointStrokeOpacityHover: 1,

			labelColor: am5.color(0xffffff),
			labelOpacity: 1,
			labelColorHover: am5.color(0xB8342B),

			bubbleFillHover: am5.color(0xB8342B),
			bubbleFillOpacityHover: 1,
			bubbleStrokeHover: am5.color(0x000000),
			bubbleStrokeOpacityHover: 1,

			pixelFillOpacity: 0.8,
			pixelStroke: am5.color(0x1A1A1A),
			pixelStrokeWidth: 0.5,
			pixelStrokeOpacity: 1,

			pixelFillHover: am5.color(0x81a760),
			pixelFillOpacityHover: 1,
			pixelStrokeOpacityHover: 1,
			fillColor: am5.color(0x879c39)
		});




		this.ruleRaw<MapEditor>("MapEditor").setAll({
			theme: "dark"
		});

		this.ruleRaw<MapSerializer>("MapSerializer").setAll({
			saveZoom: false
		});

		{
			const rule = this.ruleRaw<am5.Rectangle>("RoundedRectangle", ["button", "background", "zoomcontrol"]);

			rule.setAll({
				fillOpacity:0,
				strokeOpacity:0.1
			});

			rule.states.create("hover", {
				fillOpacity:0.2,
				fill:am5.color(0xE03F35),
				strokeOpacity:0.2
			})
		}
	
		{
			const rule = this.ruleRaw<am5.Graphics>("Graphics", ["zoomcontrol"]);

			rule.setAll({
				strokeOpacity:0.1
			});

			rule.states.create("hover", {
				strokeOpacity:0.2
			})
		}
		{
			const rule = this.ruleRaw<am5.RoundedRectangle>("RoundedRectangle", ["zoomcontrol", "light"]);

			rule.setAll({
				stroke:am5.color(0x000000)
			});
		}	
		
		{
			const rule = this.ruleRaw<am5.Graphics>("Graphics", ["zoomcontrol", "light"]);

			rule.setAll({
				stroke:am5.color(0x000000)
			});
		}		


		{
			const rule = this.ruleRaw<am5.Rectangle>("RoundedRectangle", ["resizer", "grip", "dark"]);

			rule.setAll({
				strokeOpacity: 1,
				strokeWidth: 1,
				fillOpacity: 1,
				stroke: am5.color(0xdadada),
				fill: am5.color(0x1A1A1A),
			});
		}

		{
			const rule = this.ruleRaw<am5.Rectangle>("Rectangle", ["resizer", "rectangle", "dark"]);
			rule.setAll({
				strokeOpacity: 1,
				strokeWidth: 1,
				stroke: am5.color(0xdadada)
			});
		}


		{
			const rule = this.ruleRaw<am5.Rectangle>("RoundedRectangle", ["resizer", "grip", "light"]);

			rule.setAll({
				strokeOpacity: 1,
				strokeWidth: 1,
				fillOpacity: 1,
				stroke: am5.color(0x555555),
				fill: am5.color(0xf1f1f1),
			});
		}

		{
			const rule = this.ruleRaw<am5.Rectangle>("Rectangle", ["resizer", "rectangle", "light"]);
			rule.setAll({
				strokeOpacity: 1,
				strokeWidth: 1,
				stroke: am5.color(0x555555)
			});
		}

		this.ruleRaw<am5map.MapPolygon>("MapPolygon", ["polygon", "background", "dark"]).setAll({
			fill: am5.color(0x222222),
			fillOpacity: 1,
			stroke: am5.color(0x222222),
			strokeOpacity: 1
		});

		this.ruleRaw<am5map.MapPolygon>("MapPolygon", ["polygon", "background", "light"]).setAll({
			fill: am5.color(0xe6e6e6),
			fillOpacity: 1,
			stroke: am5.color(0xe6e6e6),
			strokeOpacity: 1
		});


		this.ruleRaw<am5map.MapPolygon>("Label").setAll({
			centerX: am5.p50,
			centerY: am5.p50
		});
		
		{
			const r = this.ruleRaw<am5.Graphics>("Graphics", ["map", "point"])

			r.setAll({
				strokeWidth: 1,
				nonScalingStroke: true,
				centerX: am5.p50,
				centerY: am5.p50,
				tooltipY: 0
			})
		}

		{
			const r = this.ruleRaw<am5.Graphics>("Graphics", ["map", "point", "Empty"])

			r.setAll({
				opacity: 0
			});

			r.states.create("default", {
				opacity: 0
			});

			r.states.create("hover", {
				opacity: 0.5
			});
		}

		{
			const r = this.ruleRaw<am5map.MapLine>("MapLine"); l

			r.setAll({
				active: false
			})

			r.states.create("default", {
				toggleKey: "active"
			})
		}

		this.ruleRaw<am5map.MapLine>("MapLine", ["grid", "light"]).setAll({
			stroke: am5.color(0x000000),
			strokeOpacity: 0.04
		});

		this.ruleRaw<am5map.MapLine>("MapLine", ["grid", "dark"]).setAll({
			stroke: am5.color(0xffffff),
			strokeOpacity: 0.04
		});

		this.ruleRaw<am5.Circle>("Circle", ["map", "point", "circle"]).setAll({
			radius: 5,
		})

		this.ruleRaw<am5.Rectangle>("Rectangle", ["map", "point", "rectangle"]).setAll({
			width: 10,
			height: 10
		})

		this.ruleRaw<am5.Star>("Star", ["map", "point", "star5"]).setAll({
			radius: 8,
			innerRadius: 4,
			spikes: 5
		})

		this.ruleRaw<am5.Star>("Star", ["map", "point", "star7"]).setAll({
			radius: 8,
			innerRadius: 5,
			spikes: 7
		})

		this.ruleRaw<am5.Star>("Star", ["map", "point", "star10"]).setAll({
			radius: 8,
			innerRadius: 6,
			spikes: 10
		})


		this.ruleRaw<am5.Star>("Star", ["map", "point", "hexagon"]).setAll({
			radius: 8,
			innerRadius: 8,
			spikes: 3
		})

		this.ruleRaw<am5.Star>("Rectangle", ["map", "point", "diamond"]).setAll({
			width: 10,
			height: 10,
			rotation: 45
		})

		// Labels
		this.ruleRaw<am5.Label>("Label", ["map", "label"]).setAll({
			paddingLeft: 5,
			paddingRight: 5,
			paddingTop: 5,
			paddingBottom: 5,
			tooltipY: 0
		});

		{
			const r = this.ruleRaw<am5.RoundedRectangle>("RoundedRectangle", ["map", "label", "background"])

			r.setAll({
				fill: am5.color(0x000000),
				fillOpacity: 0,
				stroke: am5.color(0x000000),
				strokeDasharray: [1, 1],
				strokeWidth: 1,
				strokeOpacity: 0
			});

			r.states.create("active", {
				strokeOpacity: 0.9
			});
		}



		/**
		 * ========================================================================
		 * UI-related
		 * ========================================================================
		 */
		this.ruleRaw<Box>("Box").setAll({
			// width: "300px",
			// height: "300px"
		});

		this.ruleRaw<Toolbar>("Toolbar").setAll({
			layout: "vertical"
		});

		this.ruleRaw<TabToolbar>("TabToolbar").setAll({
			layout: "horizontal"
		});

		this.ruleRaw<PixelsButton>("PixelsButton").setAll({
			title: l.translateAny("Generate a pixel map"),
			// pixelType: "Circle",
			// fillOpacity: 0.8,
			// strokeColor: am5.color(0xffffff),
			// strokeOpacity: 1,
			// strokeWidth: 1
		});

		this.ruleRaw<BubblesButton>("BubblesButton").setAll({
			title: l.translateAny("Bubbles")
		});

		this.ruleRaw<PointsButton>("PointsButton").setAll({
			title: l.translateAny("Points"),
		});

		this.ruleRaw<LinesButton>("LinesButton").setAll({
			title: l.translateAny("Lines"),
		});

		this.ruleRaw<SettingsButton>("SettingsButton").setAll({
			title: l.translateAny("Settings"),
		});

		this.ruleRaw<ResizerButton>("ResizerButton").setAll({
			title: l.translateAny("Resizer"),
		});

		this.ruleRaw<ResizerButton>("AboutButton").setAll({
			title: l.translateAny("About this tool"),
		});

		this.ruleRaw<DataButton>("DataButton").setAll({
			title: l.translateAny("Data"),
		});

		this.ruleRaw<FillButton>("FillButton").setAll({
			title: l.translateAny("Color map objects"),
		});

		this.ruleRaw<DownloadButton>("SaveButton").setAll({
			title: l.translateAny("Save map data")
		});

		this.ruleRaw<DownloadButton>("DownloadButton").setAll({
			title: l.translateAny("Download the map")
		});

		// this.ruleRaw<AccordionControl>("AccordionControl").setAll({
		// 	className: "am5-full"
		// });

		this.ruleRaw<ListControl>("ListControl").setAll({
			deletable: true,
			searchable: true,
			allowMulti: true,
			usePropertyToolbar: true,
			emptyContent: l.translateAny("The list is empty.")
		});

		this.ruleRaw<PolygonListControl>("PolygonListControl").setAll({
			deletable: false
		});

		this.ruleRaw<PointListControl>("PointListControl").setAll({
			emptyContent: l.translateAny("No points, yet. Use Points tool to add some.")
		});

		this.ruleRaw<LineListControl>("LineListControl").setAll({
			emptyContent: l.translateAny("No lines, yet. Use Lines tool to add some.")
		});

		this.ruleRaw<BubbleListControl>("BubbleListControl").setAll({
			emptyContent: l.translateAny("Use bubbles tool to generate some.")
		});

		this.ruleRaw<LabelListControl>("LabelListControl").setAll({
			emptyContent: l.translateAny("No labels, yet. Use Labels tool to add some.")
		});

		this.ruleRaw<PointTypeControl>("PointTypeControl").setAll({
			value: "Balloon",
			customPath: true,
			pointTypes: {
				"Place": { name: l.translateAny("Place") },
				"Target": { name: l.translateAny("Target") },
				"Balloon": { name: l.translateAny("Balloon") },
				"Circle": { name: l.translateAny("Circle") },
				"Rectangle": { name: l.translateAny("Rectangle") },
				"5-point star": { name: l.translateAny("5-point star") },
				"7-point star": { name: l.translateAny("7-point star") },
				"10-point star": { name: l.translateAny("10-point star") },
				"Plane": { name: l.translateAny("Plane") },
				"Car": { name: l.translateAny("Car") },
				"Ship": { name: l.translateAny("Ship") },
				"Bike": { name: l.translateAny("Bike") },
				"Man": { name: l.translateAny("Man") },
				"User": { name: l.translateAny("User") },
				"Happy": { name: l.translateAny("Happy") },
				"Sad": { name: l.translateAny("Sad") },
				"Run": { name: l.translateAny("Run") },

				"Dog": { name: l.translateAny("Dog") },
				"Fish": { name: l.translateAny("Fish") },
				"Food": { name: l.translateAny("Food") },
				"Wine": { name: l.translateAny("Wine") },
				"Restaurant": { name: l.translateAny("Restaurant") },
				"Lock": { name: l.translateAny("Lock") },

				"Home": { name: l.translateAny("Home") },
				"Pin": { name: l.translateAny("Pin") },
				"Attach": { name: l.translateAny("Attach") },
				"Save": { name: l.translateAny("Save") },
				"Gear": { name: l.translateAny("Gear") },
				"Tooltip": { name: l.translateAny("Tooltip") },
				"Share": { name: l.translateAny("Share") },
				"Like": { name: l.translateAny("Like") },
				"Dislike": { name: l.translateAny("Dislike") },
				"Globe": { name: l.translateAny("Globe") },
				"Cart": { name: l.translateAny("Cart") },
				"Heart": { name: l.translateAny("Heart") },

				"Picture": { name: l.translateAny("Picture") },
				"Cross": { name: l.translateAny("Cross") },
				"Recycle": { name: l.translateAny("Recycle") },
				"Undo": { name: l.translateAny("Undo") },
				"Check": { name: l.translateAny("Check") },
				"Mail": { name: l.translateAny("Mail") },
				"Phone": { name: l.translateAny("Phone") },
				"Search": { name: l.translateAny("Search") },
				"Menu": { name: l.translateAny("Menu") },
				"Monitor": { name: l.translateAny("Monitor") },
				"Cloud": { name: l.translateAny("Cloud") },
				"Left": { name: l.translateAny("Left") },
				"Right": { name: l.translateAny("Right") },
				"Top": { name: l.translateAny("Top") },
				"Bottom": { name: l.translateAny("Bottom") }
			}
		});

		this.ruleRaw<PointTypeControl>("PointTypeControl", ["lines"]).setAll({
			value: "Target",
			pointTypes: {
				"Empty": { name: l.translateAny("No icon") },
				"Place": { name: l.translateAny("Place") },
				"Target": { name: l.translateAny("Target") },
				"Balloon": { name: l.translateAny("Balloon") },
				"Circle": { name: l.translateAny("Circle") },
				"Rectangle": { name: l.translateAny("Rectangle") },
				"5-point star": { name: l.translateAny("5-point star") },
				"7-point star": { name: l.translateAny("7-point star") },
				"10-point star": { name: l.translateAny("10-point star") },
				"Plane": { name: l.translateAny("Plane") },
				"Car": { name: l.translateAny("Car") },
				"Ship": { name: l.translateAny("Ship") },
				"Bike": { name: l.translateAny("Bike") },
				"Man": { name: l.translateAny("Man") },
				"User": { name: l.translateAny("User") },
				"Happy": { name: l.translateAny("Happy") },
				"Sad": { name: l.translateAny("Sad") },
				"Run": { name: l.translateAny("Run") },

				"Dog": { name: l.translateAny("Dog") },
				"Fish": { name: l.translateAny("Fish") },
				"Food": { name: l.translateAny("Food") },
				"Wine": { name: l.translateAny("Wine") },
				"Restaurant": { name: l.translateAny("Restaurant") },
				"Lock": { name: l.translateAny("Lock") },

				"Home": { name: l.translateAny("Home") },
				"Pin": { name: l.translateAny("Pin") },
				"Attach": { name: l.translateAny("Attach") },
				"Save": { name: l.translateAny("Save") },
				"Gear": { name: l.translateAny("Gear") },
				"Tooltip": { name: l.translateAny("Tooltip") },
				"Share": { name: l.translateAny("Share") },
				"Like": { name: l.translateAny("Like") },
				"Dislike": { name: l.translateAny("Dislike") },
				"Globe": { name: l.translateAny("Globe") },
				"Cart": { name: l.translateAny("Cart") },
				"Heart": { name: l.translateAny("Heart") },

				"Picture": { name: l.translateAny("Picture") },
				"Cross": { name: l.translateAny("Cross") },
				"Recycle": { name: l.translateAny("Recycle") },
				"Undo": { name: l.translateAny("Undo") },
				"Check": { name: l.translateAny("Check") },
				"Mail": { name: l.translateAny("Mail") },
				"Phone": { name: l.translateAny("Phone") },
				"Search": { name: l.translateAny("Search") },
				"Menu": { name: l.translateAny("Menu") },
				"Monitor": { name: l.translateAny("Monitor") },
				"Cloud": { name: l.translateAny("Cloud") },
				"Left": { name: l.translateAny("Left") },
				"Right": { name: l.translateAny("Right") },
				"Top": { name: l.translateAny("Top") },
				"Bottom": { name: l.translateAny("Bottom") }
			}
		});

		this.ruleRaw<PointTypeControl>("PointTypeControl", ["pixels"]).setAll({
			value: "Circle",
			pointTypes: {
				"Circle": { name: l.translateAny("Circle") },
				"Rectangle": { name: l.translateAny("Rectangle") },
				"Diamond": { name: l.translateAny("Diamond") },
				"Hexagon": { name: l.translateAny("Hexagon") },
			}
		});

		this.ruleRaw<PointTypeControl>("PointTypeControl", ["bubbles"]).setAll({
			value: "Diamond",
			pointTypes: {
				"Circle": { name: l.translateAny("Circle") },
				"Rectangle": { name: l.translateAny("Rectangle") },
				"Diamond": { name: l.translateAny("Diamond") },
				"Hexagon": { name: l.translateAny("Hexagon") },
			}
		});

	}
}
